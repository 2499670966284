import React, { useEffect, useState } from 'react';
import { useAuth } from '../../AuthContext';
import './AccountModal.css';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Select , message } from 'antd';

const ManageBankModal = ({ visible, onClose, onLogin, onRegisterClick, onCommissionClick, onWheelClick, onInviteFriendClick }) => {
    const { auth} = useAuth();
    const [selectedBankAccount, setSelectedBankAccount] = useState(0);

    const [userData, setUserData] = useState([]);
    const [isBankAccountAvailable, setIsBankAccountAvailable] = useState(false);

    const { t } = useTranslation();

    const navigate = useNavigate();
    
    const handleEdit = () => {
        navigate(`/manage-bank/edit/${selectedBankAccount}`);
    };

    const handleAddAccount = () => {
        navigate(`/manage-bank/add`);
    };

    const changeMainBankAccount = async() => {

        console.log(auth.userData.bankAccounts);
        if (!selectedBankAccount) {
        message.error('Please select a bank account first.');
        return;
        }

        const values = {
        memberId: auth.userData.memberId,
        bankAccountId: selectedBankAccount
        };

        console.log('Form values:', JSON.stringify(values));

        const method = "PUT";

        var apiUrl = `${process.env.REACT_APP_API_URL}/member/bankaccount/setmain`;
        
        const requestOptions = {
            method,
            headers: { "Authorization": `Bearer ${auth.token}`, "Content-Type": "application/json" },
            body: JSON.stringify(values)
        };

        try {
        const response = await fetch(apiUrl, requestOptions);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        message.success('Bank account set successfully!');
        navigate('/profile');
        window.location.reload();
        } catch (error) {
            console.error('Error posting member:', error);
        }
    }

    useEffect(() => {
        if (auth.userData) {
            setUserData(auth.userData); // Directly set the bankAccounts array
            setIsBankAccountAvailable(auth.userData.bankAccounts.length > 0);
        }
    }, [auth]);

    if (!auth.userData) {
        return <div>Loading...</div>;
    }

    if (!visible) return null;

    return (
        <>
            <div className="account-modal-container">
                <button className="account-button-close" onClick={onClose}>&times;</button>

                <div className="account-modal-content">
                    <h1>{t('manage_bank_account')}</h1>

                    <div className="profile-input-container">

                        <div className="profile-input">
                            <label>{t('select_main_bank_account')}</label>
                            <Select placeholder="Select Bank Account" 
                                    onSelect={(value) => setSelectedBankAccount(value)} 
                                    disabled={!isBankAccountAvailable}
                                    dropdownStyle={{ zIndex: 1050 }}
                                    getPopupContainer={trigger => trigger.parentNode} 
                            >
                            {isBankAccountAvailable ? (
                                userData.bankAccounts.map(account => (
                                <Select.Option
                                    key={account.id}
                                    value={account.id}
                                >
                                    {account.bankName} - {account.accountNumber} - {account.fullName}
                                </Select.Option>
                                ))
                            ) : (
                                <Select.Option key={0} value={0} disabled>Loading bank accounts...</Select.Option>
                            )}
                            </Select>
                        </div>
                    
                        <div className="add-edit-bank-container">
                            {isBankAccountAvailable ? 
                            <a className="profile-submit-bank" style={{marginRight: '10px'}} onClick={changeMainBankAccount}>ยืนยัน</a> 
                            : (<></>)
                            }
                            
                            <a className="profile-add-bank" onClick={handleAddAccount}>เพิ่ม</a>
                            {selectedBankAccount != 0 ? 
                            <a className="profile-edit-bank" style={{marginLeft: '10px'}} onClick={handleEdit}>แก้ไข</a>
                            : (<></>)
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};

export default ManageBankModal;
