import React, { useState, useContext } from 'react';
import { Input } from 'antd';
import Cookies from 'js-cookie';
import './RegisterModal.css';
import { useTranslation } from 'react-i18next';

const LoginModal = ({ visible, onClose, onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [registerState, setRegisterState] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');

  const { t } = useTranslation();
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleCloseClick = () => {
    setUsername('');
    setPassword('');
    setConfirmPassword('');
    setErrorMessage('');
    setRegisterState(0);
    onClose();
  }

  const handleNext = () => {
    if(registerState === 0){
      if(username.length >= 8){
        setRegisterState(registerState + 1);
        setErrorMessage('');
      }
      else{
        setErrorMessage('Telephone Number field is required');
      }
    }
    else if (registerState === 1) {
      if (password !== confirmPassword) {
        setErrorMessage('Passwords do not match');
        return;
      }
      else{
        setErrorMessage('');
        handlePasswordSubmit();
        setRegisterState(registerState + 1);
      }
    }
  };

  const renderContent = () => {
    switch (registerState) {
      case 0:
        return (
          <>
            <img src="/register_button.png" className="register-modal-logo"></img>
            <h1>{t('enter_your_phone_number')}</h1>
            <div className="register-input-container">
              <img className="register-button-image" src="/phone_icon.png"></img>
              <Input
                placeholder="Phone Number"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>


            <button className="register-button" onClick={handleNext}>{t('next')}</button>
          </>
        );
      case 1:
        return (
          <>
            <h1>{t('create_your_password')}</h1>
            <div className="register-input-container">
              <img className="register-button-image" src="/lock_icon.png"></img>
              <Input.Password
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{marginBottom: "10px"}}
              />
            </div>

            <div className="register-input-container">
              <img className="register-button-image" src="/lock_icon.png"></img>
              <Input.Password
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>

            <button className="register-button" onClick={handlePasswordSubmit}>{t('register')}</button>
          </>
        );
      case 2:
        return (
          <>
            <h1>{t('register_completed')}</h1>
            <Input.Password
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{marginBottom: "10px"}}
            />
            <Input.Password
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />

            <img src="/register_text_button.png" className="register-button" onClick={handlePasswordSubmit}></img>
          </>
        );
      default:
        return null;
    }
  };

  const handlePasswordSubmit = async() => {
    const affiliateCode = Cookies.get('winner_affiliate_code') || '';

    const raw = JSON.stringify({
        "telephoneNumber": username,
        "password": password,
        "affiliateCode": affiliateCode
    });

    try {
      const response = await fetch(apiUrl+'/api/register/store', {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        },
        body: raw
      });

      const data = await response.json();
  
      if (!response.ok) {
        setErrorMessage(data.message);
      }
      else{
        setRegisterState(3);
      }
      
    } catch (error) {
        console.error("handlePasswordSubmit :", error);
    }
  };

  const handleLoginClick = () => {
    setUsername('');
    setPassword('');
    setConfirmPassword('');
    setErrorMessage('');
    setRegisterState(0);
    onClose();
    onLogin();
  }

  if (!visible) return null;

  return (
    <>
      <div className="modal-overlay"/>

      <div className="register-modal-container">
          <button className="register-button-close" onClick={handleCloseClick}>&times;</button>

          <div className="login-header-container">
            <button className="login-header-button-active">{t('register')}</button>
            <button className="login-header-button" onClick={handleLoginClick}>{t('login')}</button>
          </div>

          <div className="register-modal-content">
            
            {errorMessage && <p className="register-error-message">{errorMessage}</p>}
            {renderContent()}
          </div>
      </div>
    </>
  );
};

export default LoginModal;