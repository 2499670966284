import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './locales/en/translation.json';
import translationTH from './locales/th/translation.json';
import translationMY from './locales/my/translation.json';
import translationLA from './locales/la/translation.json';

const resources = {
  en: {
    translation: translationEN
  },
  th: {
    translation: translationTH
  },
  my: {
    translation: translationMY
  },
  la: {
    translation: translationLA
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'th', // default language
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
