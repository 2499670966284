import React, { useState, useEffect } from 'react';
import HeaderWithMenu from '../components/HeaderWithMenu';
import FooterWithProvider from '../components/FooterWithProvider';
import FooterWithCopyright from '../components/FooterWithCopyright';
import FooterWithButton from '../components/FooterWithButton';
import AnnouncementBar from '../components/AnnouncementBar';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import LoginModal from '../components/LoginModal';
import RegisterModal from '../components/RegisterModal';
import MenuModal from '../components/MenuModal';
import AccountSidebar from '../components/AccountSidebar';

import AccountModal from '../components/AccountSection/AccountModal';

import DepositModal from '../components/AccountSection/DepositModal';
import WithdrawModal from '../components/AccountSection/WithdrawModal';
import TransactionHistoryModal from '../components/AccountSection/TransactionHistoryModal';
import PromotionHistoryModal from '../components/AccountSection/PromotionHistoryModal';
import RetrieveBonusModal from '../components/AccountSection/RetrieveBonusModal';
import CommissionModal from '../components/CommissionModal';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { useTranslation } from 'react-i18next';

import WheelGameModal from '../components/WheelGameModal';

import './InviteFriend.css';

function WheelPage() {
    const appURL = process.env.REACT_APP_URL;

    const navigate = useNavigate();

    const { auth } = useAuth();

    const { t } = useTranslation();

    const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
    const [isRegisterModalVisible, setIsRegisterModalVisible] = useState(false);
    const [isMenuModalVisible, setIsMenuModalVisible] = useState(false);
    const [isAccountSidebarVisible, setIsAccountSidebarVisible] = useState(false);
    
    const [isAccountModalVisible, setIsAccountModalVisible] = useState(false);
    const [isDepositModalVisible, setIsDepositModalVisible] = useState(false);
    const [isWithdrawModalVisible, setIsWithdrawModalVisible] = useState(false);
    const [isTransactionHistoryModalVisible, setIsTransactionHistoryModalVisible] = useState(false);
    const [isPromotionHistoryModalVisible, setIsPromotionHistoryModalVisible] = useState(false);
    const [isRetrieveBonusModalVisible, setIsRetrieveBonusModalVisible] = useState(false);
    
    const [isCommissionModalVisible, setIsCommissionModalVisible] = useState(false);
    const [isWheelModalVisible, setIsWheelModalVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const showLoginModal = () => setIsLoginModalVisible(true);
    const cancelLoginModal = () => setIsLoginModalVisible(false);

    const showRegisterModal = () => setIsRegisterModalVisible(true);
    const cancelRegisterModal = () => setIsRegisterModalVisible(false);

    const showMenuModal = () => setIsMenuModalVisible(true);
    const cancelMenuModal = () => setIsMenuModalVisible(false);

    const showAccountSidebar = () => setIsAccountSidebarVisible(true);
    const cancelAccountSidebar = () => setIsAccountSidebarVisible(false);

    const showAccountModal = () => setIsAccountModalVisible(true);
    const cancelAccountModal = () => setIsAccountModalVisible(false);

    const showDepositModal = () => setIsDepositModalVisible(true);
    const cancelDepositModal = () => setIsDepositModalVisible(false);

    const showWithdrawModal = () => setIsWithdrawModalVisible(true);
    const cancelWithdrawModal = () => setIsWithdrawModalVisible(false);

    const showTransactionHistoryModal = () => setIsTransactionHistoryModalVisible(true);
    const cancelTransactionHistoryModal = () => setIsTransactionHistoryModalVisible(false);

    const showPromotionHistoryModal = () => setIsPromotionHistoryModalVisible(true);
    const cancelPromotionHistoryModal = () => setIsPromotionHistoryModalVisible(false);

    const showRetrieveBonusModal = () => setIsRetrieveBonusModalVisible(true);
    const cancelRetrieveBonusModal = () => setIsRetrieveBonusModalVisible(false);

    const showCommissionModal = () =>  setIsCommissionModalVisible(true);
    const cancelCommissionModal = () => setIsCommissionModalVisible(false);

    const showWheelModal = () =>  setIsWheelModalVisible(true);
    const cancelWheelModal = () => setIsWheelModalVisible(false);

    const handleGameClick = () => navigate("/");

    useEffect(() => {
        if (auth.isAuthenticated && auth.userData) {
        let affiliateUrl = process.env.REACT_APP_URL+"?"+auth.userData.ownedAffiliate.code;
        setInputValue(affiliateUrl);
        }
    }, [auth]);

    const handleCopy = () => {
        navigator.clipboard.writeText(inputValue).then(() => {
            alert('Copied to clipboard!');
        }).catch(err => {
            alert('Failed to copy!');
        });
    };

    return (
        <div className="ranking-background-element">
        <HeaderWithMenu onLoginClick={showLoginModal} onMenuClick={showMenuModal} onProfileClick={showAccountSidebar}/>
        <AnnouncementBar />
        <div className="invite-friend-content-container">
            <img className="modal-title-image" src="/wheel_icon_light.png"></img>
            <h1>{t('wheel')}</h1>

            <h1>หมุนวงล้อเพื่อรับรางวัลได้ที่นี่</h1>
            <h2>ท่านมีสิทธิ์ในการหมุนอีก  2  ครั้ง</h2>
            <h2>ได้รับสิทธิ์ครั้งต่อไปใน 12 ชั่วโมง 4 นาที </h2>
            <button onClick={showWheelModal}>หมุนเลย !</button>

            <h2>1. 5 บาท (โอกาส 50%)</h2>
            <h2>2. 10 บาท (25%)</h2>
            <h2>3. ไอโฟน (0.1%)</h2>
            <h2>4. ทอง (0.05%)</h2>
            <h2>5. รถมอไซ (0.01%)</h2>
            
        </div>
        
        <FooterWithProvider />
        <FooterWithCopyright />
        <FooterWithButton onRegisterClick={showRegisterModal} onLogin={showLoginModal} onHomeClick={handleGameClick}/>

        <LoginModal visible={isLoginModalVisible} onClose={cancelLoginModal} onRegisterClick={showRegisterModal} />
        <RegisterModal visible={isRegisterModalVisible} onClose={cancelRegisterModal} onLogin={showLoginModal}/>
        <MenuModal visible={isMenuModalVisible} onClose={cancelMenuModal} onLogin={showLoginModal} onRegisterClick={showRegisterModal} onCommissionClick={showCommissionModal}/>
        <AccountSidebar visible={isAccountSidebarVisible} onClose={cancelAccountSidebar} onAccountClick={showAccountModal} onDepositClick={showDepositModal} onWithdrawClick={showWithdrawModal} onTransactionHistoryClick={showTransactionHistoryModal} onPromotionHistoryClick={showPromotionHistoryModal} onGetBonusClick={showRetrieveBonusModal}/>
        
        <AccountModal visible={isAccountModalVisible} onClose={cancelAccountModal}/>
        <DepositModal visible={isDepositModalVisible} onClose={cancelDepositModal}/>
        <WithdrawModal visible={isWithdrawModalVisible} onClose={cancelWithdrawModal}/>
        <TransactionHistoryModal visible={isTransactionHistoryModalVisible} onClose={cancelTransactionHistoryModal}/>
        <PromotionHistoryModal visible={isPromotionHistoryModalVisible} onClose={cancelPromotionHistoryModal}/>
        <RetrieveBonusModal visible={isRetrieveBonusModalVisible} onClose={cancelRetrieveBonusModal}/>

        <CommissionModal visible={isCommissionModalVisible} onClose={cancelCommissionModal} />
        <WheelGameModal visible={isWheelModalVisible} onClose={cancelWheelModal} />
        
        </div>
    );
}

export default WheelPage;
