import React, { useState, useEffect } from 'react';
import HeaderWithMenu from '../components/HeaderWithMenu';
import FooterWithProvider from '../components/FooterWithProvider';
import FooterWithCopyright from '../components/FooterWithCopyright';
import FooterWithButton from '../components/FooterWithButton';
import AnnouncementBar from '../components/AnnouncementBar';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useAuth } from '../AuthContext';
import LoginModal from '../components/LoginModal';
import RegisterModal from '../components/RegisterModal';
import MenuModal from '../components/MenuModal';
import AccountSidebar from '../components/AccountSidebar';

import AccountModal from '../components/AccountSection/AccountModal';
import PromotionModal from '../components/PromotionModal';
import CommissionModal from '../components/CommissionModal';
import WheelGameModal from '../components/WheelGameModal';
import { useTranslation } from 'react-i18next';

import './PromotionPage.css';

function PromotionPage() {
  
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [isRegisterModalVisible, setIsRegisterModalVisible] = useState(false);
  const [isMenuModalVisible, setIsMenuModalVisible] = useState(false);
  const [isAccountSidebarVisible, setIsAccountSidebarVisible] = useState(false);
  
  const [isAccountModalVisible, setIsAccountModalVisible] = useState(false);
  const [isDepositModalVisible, setIsDepositModalVisible] = useState(false);
  const [isWithdrawModalVisible, setIsWithdrawModalVisible] = useState(false);
  const [isTransactionHistoryModalVisible, setIsTransactionHistoryModalVisible] = useState(false);
  const [isPromotionHistoryModalVisible, setIsPromotionHistoryModalVisible] = useState(false);
  const [isGetBonusModalVisible, setIsGetBonusModalVisible] = useState(false);
  
  const [isPromotionModalVisible, setIsPromotionModalVisible] = useState(false);
  const [isCommissionModalVisible, setIsCommissionModalVisible] = useState(false);
  const [isWheelModalVisible, setIsWheelModalVisible] = useState(false);

  const { t } = useTranslation();
  
  const showLoginModal = () => setIsLoginModalVisible(true);
  const cancelLoginModal = () => setIsLoginModalVisible(false);

  const showRegisterModal = () => setIsRegisterModalVisible(true);
  const cancelRegisterModal = () => setIsRegisterModalVisible(false);

  const showMenuModal = () => setIsMenuModalVisible(true);
  const cancelMenuModal = () => setIsMenuModalVisible(false);

  const showAccountSidebar = () => setIsAccountSidebarVisible(true);
  const cancelAccountSidebar = () => setIsAccountSidebarVisible(false);

  const showAccountModal = () => setIsAccountModalVisible(true);
  const cancelAccountModal = () => setIsAccountModalVisible(false);

  const showDepositModal = () => setIsDepositModalVisible(true);
  const cancelDepositModal = () => setIsDepositModalVisible(false);

  const showWithdrawModal = () => setIsWithdrawModalVisible(true);
  const cancelWithdrawModal = () => setIsWithdrawModalVisible(false);

  const showTransactionHistoryModal = () => setIsTransactionHistoryModalVisible(true);
  const cancelTransactionHistoryModal = () => setIsTransactionHistoryModalVisible(false);

  const showPromotionHistoryModal = () => setIsPromotionHistoryModalVisible(true);
  const cancelPromotionHistoryModal = () => setIsPromotionHistoryModalVisible(false);

  const showGetBonusModal = () => setIsGetBonusModalVisible(true);
  const cancelGetBonusModal = () => setIsGetBonusModalVisible(false);

  const showPromotionModal = () => setIsPromotionModalVisible(true);
  const cancelPromotionModal = () => setIsPromotionModalVisible(false);

  const showCommissionModal = () =>  setIsCommissionModalVisible(true);
  const cancelCommissionModal = () => setIsCommissionModalVisible(false);

  const showWheelModal = () =>  setIsWheelModalVisible(true);
  const cancelWheelModal = () => setIsWheelModalVisible(false);

  const handleGameClick = () => console.log('Game Click');

  return (
    <div className="promotion-background-element">
      <HeaderWithMenu onLoginClick={showLoginModal} onMenuClick={showMenuModal} onProfileClick={showAccountSidebar}/>
      <AnnouncementBar />
      <div className="promotion-content-container">
        <h1 className="promotion-title">{t('promotion_page_title')}</h1>
        <img src="/slide1.jpg" alt="Promotion 1" className="promotion-image" />
        <img src="/slide2.jpg" alt="Promotion 2" className="promotion-image" />
        <img src="/slide3.jpg" alt="Promotion 3" className="promotion-image" />
        <img src="/slide1.jpg" alt="Promotion 1" className="promotion-image" />
        <img src="/slide2.jpg" alt="Promotion 2" className="promotion-image" />
      </div>
      
      <FooterWithProvider />
      <FooterWithCopyright />
      <FooterWithButton onRegisterClick={showRegisterModal} onLogin={showLoginModal} onHomeClick={handleGameClick} onPromotion={showPromotionModal}/>

      <LoginModal visible={isLoginModalVisible} onClose={cancelLoginModal} onRegisterClick={showRegisterModal} />
      <RegisterModal visible={isRegisterModalVisible} onClose={cancelRegisterModal} onLogin={showLoginModal}/>
      <MenuModal visible={isMenuModalVisible} onClose={cancelMenuModal} onLogin={showLoginModal} onRegisterClick={showRegisterModal} onCommissionClick={showCommissionModal} onWheelClick={showWheelModal} />
      <AccountSidebar visible={isAccountSidebarVisible} onClose={cancelAccountSidebar} onAccountClick={showAccountModal} onDepositClick={showDepositModal} onWithdrawClick={showWithdrawModal} onTransactionHistoryClick={showTransactionHistoryModal} onPromotionHistoryClick={showPromotionHistoryModal} onGetBonusClick={showGetBonusModal} />
      
      <AccountModal visible={isAccountModalVisible} onClose={cancelAccountModal}/>
      <PromotionModal visible={isPromotionModalVisible} onClose={cancelPromotionModal} />
      <CommissionModal visible={isCommissionModalVisible} onClose={cancelCommissionModal} />
      <WheelGameModal visible={isWheelModalVisible} onClose={cancelWheelModal} />
    </div>
  );
}

export default PromotionPage;
