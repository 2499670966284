import { useState, useEffect, useCallback, useRef } from 'react';

const useGameData = (paginateProviders = false) => {
  const [providers, setProviders] = useState([]);
  const [loadingProvider, setLoadingProvider] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [providerHasMore, setProviderHasMore] = useState(true); // Flag for provider pagination

  const [categories, setCategories] = useState([]);
  const [loadingCategory, setLoadingCategory] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("SLOT");

  const [types, setTypes] = useState([]);
  const [loadingType, setLoadingType] = useState(false);
  const [selectedType, setSelectedType] = useState(null);

  const [games, setGames] = useState([]);
  const [loadingGame, setLoadingGame] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [limit] = useState(10);

  const [isCategorySet, setIsCategorySet] = useState(false);

  const [searchKeyword, setSearchKeyword] = useState('');

  const gamePageRef = useRef(0);
  const providerPageRef = useRef(0);

  const apiUrl = process.env.REACT_APP_API_URL;

  // Fetch Providers with conditional pagination
  const fetchProviders = useCallback(async () => {
    if (loadingProvider || !providerHasMore) return;
    
    setLoadingProvider(true);

    let url = `${apiUrl}/game/get-provider-lists?page=${providerPageRef.current}&limit=${limit}`;
    if (selectedCategory) {
      url += `&gameType=${selectedCategory}`;
    }

    console.log(url);

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (response.ok && Array.isArray(data)) {
        setProviders(prevProviders => [...prevProviders, ...data]);
        const newHasMore = data.length === limit;
        setProviderHasMore(newHasMore);
        if (newHasMore) {
          providerPageRef.current += 1;
        }
      } else {
        setProviderHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching providers:', error);
      setProviderHasMore(false);
    } finally {
      setLoadingProvider(false);
    }
  }, [providerHasMore, limit]);

  // Fetch Categories
  const fetchCategories = async () => {
    if (loadingCategory) return;
    setLoadingCategory(true);

    try {
      const response = await fetch(`${apiUrl}/game/get-category-lists${selectedCategory ? `?gameCategory=${selectedCategory}` : ''}`);
      const data = await response.json();

      if (response.ok && Array.isArray(data)) {
        setCategories(data);
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    } finally {
      setLoadingCategory(false);
    }
  };

  // Fetch Types
  const fetchTypes = async () => {
    if (loadingType) return;
    setLoadingType(true);

    try {
      const response = await fetch(`${apiUrl}/game/get-type-lists${selectedCategory ? `?gameCategory=${selectedCategory}` : ''}`);
      const data = await response.json();
      console.log(`${apiUrl}/game/get-type-lists${selectedCategory ? `?gameCategory=${selectedCategory}` : ''}`);

      if (response.ok && Array.isArray(data)) {
        setTypes(data);
      }
    } catch (error) {
      console.error('Error fetching types:', error);
    } finally {
      setLoadingType(false);
    }
  };

  // Fetch Games with Pagination
  // Fetch Games with Pagination
  const fetchGames = useCallback(async () => {
    if (loadingGame || !hasMore) return;

    setLoadingGame(true);

    let url = '';

    if(searchKeyword != ''){
      const searchQuery = searchKeyword ? `&search=${searchKeyword}` : '';
      const categoryQuery = selectedCategory ? `&gameCategory=${selectedCategory}` : '';
      url = `${apiUrl}/game/get-game-lists?limit=100&${searchQuery}${categoryQuery}`;
    }
    else{
      const providerQuery = selectedProvider ? `&providerName=${selectedProvider}` : '';
      const categoryQuery = selectedCategory ? `&gameCategory=${selectedCategory}` : '';
      const gameTypeQuery = selectedType ? `&gameType=${selectedType}` : '';
      url = `${apiUrl}/game/get-game-lists?limit=${limit}&page=${gamePageRef.current}${providerQuery}${categoryQuery}${gameTypeQuery}`;
    }

    
    
    try {
      const response = await fetch(url);
      const data = await response.json();
      
      if(searchKeyword == ''){
        if (response.ok && Array.isArray(data)) {
          setGames(prevGames => [...prevGames, ...data]);
          const newHasMore = data.length === limit;
          setHasMore(newHasMore);
          if (newHasMore) {
            gamePageRef.current += 1; // Update page in ref
          }
        } else {
          setHasMore(false);
        }
      }
      else{
        setGames(data);
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching games:', error);
      setHasMore(false);
    } finally {
      setLoadingGame(false);
    }
  }, [loadingGame, limit, selectedProvider, selectedCategory, searchKeyword, hasMore]);


  const handleProviderClick = (providerName) => {
    resetGames();
    setSelectedProvider(providerName !== selectedProvider ? providerName : null);
  };

  const handleTypeClick = (typeName) => {
    resetGames();
    setSelectedType(typeName !== selectedType ? typeName : null);
  };

  const handleCategorySelect = (categoryName) => {
    resetGames();
    setSelectedType(null);
    setSearchKeyword('');
    setSelectedCategory(categoryName !== selectedCategory ? categoryName : null);
    setIsCategorySet(true);
  };

  const handleSearch = (keyword) => {
    resetGames();
    setSearchKeyword(keyword);
  }

  const resetGames = () => {
    setGames([]);
    gamePageRef.current = 0;
    setHasMore(true);
  };

  // Fetch providers, categories, and types when the component mounts
  useEffect(() => {
    resetGames();
    fetchCategories();
    // fetchProviders();
    fetchTypes();
  }, [selectedCategory]); // Re-fetch when gameType or gameCategory changes

  return {
    providers,
    loadingProvider,
    providerHasMore,
    fetchProviders, // To manually fetch more providers if needed
    selectedProvider,
    handleProviderClick,
    categories,
    loadingCategory,
    selectedCategory,
    selectedType,
    setSelectedType,
    handleCategorySelect,
    types,
    loadingType,
    games,
    loadingGame,
    hasMore,
    fetchGames,
    resetGames,
    isCategorySet,
    handleTypeClick,
    handleSearch
  };
};

export default useGameData;
