import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

export const AuthContext = createContext({
    auth: { token: null, isAuthenticated: false, username: null, userData: null},
    setAuth: () => {}
});
  
export function AuthProvider({ children }) {
    const [auth, setAuth] = useState({
        token: localStorage.getItem('winner-jwt'),
        isAuthenticated: !!localStorage.getItem('winner-jwt'),
        username: localStorage.getItem('username'),
        userData: null,
    });

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const token = localStorage.getItem('winner-jwt');
        if (token && isTokenExpired(token)) {
            logout();
        } else if (token) {
            fetchUserData(auth.username);
        }
    }, []);

    useEffect(() => {
        if(auth.isAuthenticated){
            fetchUserData(auth.username);
        }
        
    }, [auth.token]);

    const login = (token, username) => {
        localStorage.setItem('winner-jwt', token);  // Store the token in localStorage for persistent authentication
        localStorage.setItem('username', username);
        setAuth({ token: token, isAuthenticated: true, username: username });
    };

    const logout = () => {
        localStorage.removeItem('winner-jwt');
        localStorage.removeItem('username');
        setAuth({ token: null, isAuthenticated: false, username: null, userData: null });
    };

    const isTokenExpired = (token) => {
        try {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        return decoded.exp < currentTime;
        } catch (error) {
        console.error('Error decoding token:', error);
        return true; // Consider token expired if there's an error
        }
    };

    const fetchUserData = async (username) => {

        try {
            const response = await fetch(apiUrl+'/member/getuser?username='+username, {
                method: 'GET',
                headers: { "Authorization": `Bearer ${auth.token}`, "Content-Type": "application/json" },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            setAuth((prevAuth) => ({ ...prevAuth, userData: data.data }));
        } catch (error) {
            console.error("Get User DATA failed:", error);
            logout();
        }
    };

    return (
        <AuthContext.Provider value={{ auth, setAuth, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
}

export function useAuth() {
    const context = useContext(AuthContext);

    if (!context) {
        console.error('useAuth must be used within an AuthProvider');
        return;
    }

    // console.log('Auth State:', context.auth);
    return context;
}