import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../AuthContext';
import { NavBar } from 'antd-mobile';
import { DollarOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import './HeaderWithMenu.css'; // Import the CSS file
import { useTranslation } from 'react-i18next';

const HeaderWithMenu = ({ onLoginClick, onMenuClick, onProfileClick }) => {
  const { auth } = useAuth();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const userDetailWindowRef = useRef(null);

  const handleHomeClick = () => navigate('/');

  const onProfileClickOld = () => {
    // Check if the tab already exists and is not closed
    if (userDetailWindowRef.current && !userDetailWindowRef.current.closed) {
      userDetailWindowRef.current.focus(); // Focus on the existing tab
      userDetailWindowRef.current.location.reload();
      return;
    }

    // Open a new tab instead of a window
    userDetailWindowRef.current = window.open(
      `${window.location.origin}/profile`,
      '_blank'
    );

    
    const syncAuthContext = () => {
      // Synchronize auth data between tabs
      userDetailWindowRef.current.localStorage.setItem('winner-jwt', auth.token);
      userDetailWindowRef.current.localStorage.setItem('username', auth.username);
    };

    // Set up the sync when the new tab loads
    userDetailWindowRef.current.onload = syncAuthContext;
  }

  return (
    <div className="header-with-menu">
      <div className="nav-container">
        <NavBar
          left={
            <div className="header-nav" onClick={onMenuClick}>
              <div className="header-nav-line1"></div>
              <div className="header-nav-line2"></div>
              <div className="header-nav-line3"></div>
            </div>
          }
          className="navbar"
          backIcon={false}
          right={
            auth.isAuthenticated ? (
              <div style={{display:"flex", alignItems: "center"}}>
                <div className="header-money-container" onClick={onProfileClick}>
                  <DollarOutlined 
                    className="header-money-icon" 
                  />
                  <p className="header-money-text">
                  {auth.userData?.wallet?.balance !== undefined ? 
                  new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(auth.userData.wallet.balance)
                  : null
                  }
                  </p>
                </div>
              </div>
              
            ) : (
              <button className="nav-login-button" onClick={onLoginClick}>{t('login')}</button>
            )
          }
        >
          <img className="header-logo" src="/long_logo.png" onClick={handleHomeClick}></img>

        </NavBar>
      </div>

    </div>
  );
};

export default HeaderWithMenu;
