import React, { useState, useContext } from 'react';
import { useAuth } from '../../AuthContext';
import './AccountModal.css';
import './DepositModal.css';
import { useTranslation } from 'react-i18next';

const DepositModal = ({ visible, onClose, onLogin, onRegisterClick, onCommissionClick, onWheelClick, onInviteFriendClick }) => {
    const { auth} = useAuth();
    const { i18n } = useTranslation();
    const [modalState, setModalState] = useState('selectMethod');

    const [amount, setAmount] = useState(0);
    const [promoCode, setPromoCode] = useState('');

    const apiUrl = process.env.REACT_APP_API_URL;

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const { t } = useTranslation();

    const handlePaypay89Click = () => {
        setModalState('enterInformation');
    }

    const handleCloseClick = () => {
        setModalState('selectMethod');
        setAmount(0);
        setPromoCode('');
        onClose();
    }

    const addMoney = (value) => {
        setAmount((prevAmount) => prevAmount + value); // Update the amount state
    }

    const changePromotion = (value) => {
        setPromoCode(value);
    } 

    const onFinish = async() => {
        if (!auth.token) {
            console.error("No token available");
            return;
        }

        const myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${auth.token}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        try {
            const response = await fetch(apiUrl + "/member/deposit/getpaypay89?username=" + auth.username+"&amount="+amount+"&promoCode="+promoCode, requestOptions);
            if (response.ok) {
                const url = await response.text();
                console.log("URL received:", url);

                // Create an anchor element for redirection
                const a = document.createElement('a');
                a.href = url;
                a.rel = 'noopener noreferrer';
                
                console.log("Attempting to redirect to:", url);
                a.click();

                console.log("Redirection successful to:", url);
            } else {
                console.error("Failed to fetch URL");
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    if (!visible) return null;

    return (
        <>
            <div className="account-modal-container">
                <button className="account-button-close" onClick={handleCloseClick}>&times;</button>

                <div className="account-modal-content">
                    <h1>{t('deposit')}</h1>

                    {modalState === 'selectMethod' && (
                        <div className="deposit-container">
                            <h1>{t('please_select_deposit_method')}</h1>
                            <img className="deposit-button" src="/paypay89_button.png" onClick={handlePaypay89Click}></img>
                            <img className="deposit-button" src="/paypay89_button.png" onClick={handlePaypay89Click}></img>
                            <img className="deposit-button" src="/paypay89_button.png" onClick={handlePaypay89Click}></img>
                        </div>
                    )}

                    {modalState === 'enterInformation' && (
                        <div className="deposit-container">
                            <div className="deposit-input">
                                <label>{t('amount')}</label>
                                <input
                                    type="text"
                                    value={amount !== 0 ? amount : ''} // Show empty string if amount is 0
                                    placeholder={amount === 0 ? 'ฝากขั้นต่ำ 100' : ''} // Show placeholder if amount is 0
                                    onChange={(e) => setAmount(parseFloat(e.target.value) || 0)} 
                                />
                            </div>

                            <div className="deposit-add-money-container">
                                <button onClick={() => addMoney(100)}>100</button>
                                <button onClick={() => addMoney(300)}>300</button>
                                <button onClick={() => addMoney(500)}>500</button>
                                <button onClick={() => addMoney(1000)}>1,000</button>
                                <button onClick={() => addMoney(5000)}>5,000</button>
                                <button onClick={() => addMoney(10000)}>10,000</button>
                            </div>
                            

                            <div className="deposit-input">
                                <label>Promotion Code</label>
                                <input
                                    type="text"
                                    value={promoCode}
                                    placeholder='ใส่โปรโมชั่นโค๊ด'
                                    onChange={(e) => setPromoCode(e.target.value)} 
                                />
                            </div>

                            <div className="deposit-promotion-container">
                                <button onClick={() => changePromotion("PROMO1")}>
                                    <img src="/promotion_icon.png"/>
                                    <p>สมาชิกใหม่ ฝาก 100 รับ 150</p>
                                </button>

                                <button onClick={() => changePromotion("PROMO2")}>
                                    <img src="/promotion_icon.png"/>
                                    <p>สมาชิกใหม่ ฝาก 300 รับ 500.-</p>
                                </button>

                                <button onClick={() => changePromotion("PROMO3")}>
                                    <img src="/promotion_icon.png"/>
                                    <p>สมาชิกใหม่ รับโบนัสทันที 50%</p>
                                </button>

                            </div>

                                
                            <a className="deposit-next-button" onClick={onFinish}>ถัดไป</a>

                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default DepositModal;
