import React, {useRef, useContext} from 'react';
import './FooterWithButton.css';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../ThemeContext';

const FooterWithButton = ({ onLogin, onHomeClick, onPromotion}) => {

  const { auth } = useAuth();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const userDetailWindowRef = useRef(null);

  const { theme } = useContext(ThemeContext);

  const customNavigate = (url) => {
    // Check if the tab already exists and is not closed
    if (userDetailWindowRef.current && !userDetailWindowRef.current.closed) {
      userDetailWindowRef.current.location.href = `${window.location.origin}${url}`;
      userDetailWindowRef.current.focus(); // Focus on the existing tab
      return;
    }

    // Open a new tab instead of a window
    userDetailWindowRef.current = window.open(
      `${window.location.origin}`+url,
      '_blank'
    );

    const syncAuthContext = () => {
      // Synchronize auth data between tabs
      userDetailWindowRef.current.localStorage.setItem('winner-jwt', auth.token);
      userDetailWindowRef.current.localStorage.setItem('username', auth.username);
    };

    // Set up the sync when the new tab loads
    userDetailWindowRef.current.onload = syncAuthContext;
  }

  const handleDepositClick = () => {
    if(auth.isAuthenticated){
      customNavigate('/deposit');
    }
    else{
      onLogin();
    }
  }

  const handleWithdrawClick = () => {
    if(auth.isAuthenticated){
      customNavigate('/withdraw');
    }
    else{
      onLogin();
    }
  }

  const handleContactClick = () => {

  }

  const handlePromotionClick = () => {
    navigate('/promotion');
  }

  return (
    <div className="footer-container">

      <div className="footer-button1">
        <img src={theme === 'light' ? "deposit_icon_light.png" :"/deposit_icon.png"} onClick={handleDepositClick}></img>
        <p>{t('deposit')}</p>
      </div>

      <div className="footer-button2">
        <img src={theme === 'light' ? "withdraw_icon_light.png" :"/withdraw_icon.png"} onClick={handleWithdrawClick}></img>
        <p>{t('withdraw')}</p>
      </div>
      
      <div className="home-button-wrapper" onClick={onHomeClick}>
        <img src={theme === 'light' ? "home_light.png" :"/home.png"} className="footer-button3"></img>
      </div>
      

      <div className="footer-button4">
        <img src={theme === 'light' ? "promotion_light.png" :"/promotion_icon.png"} onClick={handlePromotionClick}></img>
        <p>{t('promotion')}</p>
      </div>
      
      <div className="footer-button5">
        <img src={theme === 'light' ? "line_light.png" :"/line.png"} onClick={handleContactClick}></img>
        <p>{t('contact')}</p>
      </div>
      

      <div className="footer-background"></div>
    </div>
  );
};

export default FooterWithButton;