import React, { useState } from 'react';
import './AnnouncementBar.css'; // Import the CSS file

const AnnouncementBar = () => {

  return (
    <div className="announcement-container">
        <div className="announcement-content">
            <div className="announcement-image-container">
              <img src="/loudspeaker.png" className="announcement-image"></img>
            </div>
            <p className="announcement-text">ประกาศจาก winner ให้ทุกท่านได้ทราบทั่วกัน ประกาศจาก winner ให้ทุกท่านได้ทราบทั่วกัน</p>
        </div>
    </div>
  );
};

export default AnnouncementBar;