import React, { useState } from 'react';
import './FooterWithCopyright.css'; // Import the CSS file

const FooterWithCopyright = () => {

  return (
    <div className="footer-copyright-container">
        <p>Copyright © 2024 Winner All Rights Reserved</p>
    </div>
  );
};

export default FooterWithCopyright;