import React, { useState, useContext } from 'react';
import { useAuth } from '../../AuthContext';
import './AccountModal.css';
import './WithdrawModal.css';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const WithdrawModal = ({ visible, onClose, onLogin, onRegisterClick, onCommissionClick, onWheelClick, onInviteFriendClick }) => {
    const { auth} = useAuth();
    const { i18n } = useTranslation();

    const [modalState, setModalState] = useState('selectMethod');

    const [amount, setAmount] = useState(0);

    const apiUrl = process.env.REACT_APP_API_URL;

    const navigate = useNavigate();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const { t } = useTranslation();

    const addMoney = (value) => {
        setAmount((prevAmount) => prevAmount + value); // Update the amount state
    }

    const handlePaypay89Click = () => {
        setModalState('enterInformation');
    }

    const handleCloseClick = () => {
        setModalState('selectMethod');
        setAmount(0);
        onClose();
    }

    const onFinish = async(values) => {
        console.log('Received values:', values);

        if (!auth.token) {
            console.error("No token available");
            return;
        }

        const myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${auth.token}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        try {
            const response = await fetch(apiUrl + "/member/withdraw/request?username=" + auth.username+"&amount="+amount, requestOptions);
            if (response.ok) {
                // const data = await response.json();
                navigate('/transaction-history', { state: { filterType: 'withdraw' } });
            } else {
                console.error("Failed to fetch URL");
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };


    if (!visible) return null;

    return (
        <>
            <div className="account-modal-container">
                <button className="account-button-close" onClick={handleCloseClick}>&times;</button>

                <div className="account-modal-content">
                    <h1>{t('withdraw')}</h1>

                    {modalState === 'selectMethod' && (
                        <div className="withdraw-container">
                            <h1>{t('please_select_withdraw_method')}</h1>
                            <img className="withdraw-button" src="/paypay89_button.png" onClick={handlePaypay89Click}></img>
                            <img className="withdraw-button" src="/paypay89_button.png" onClick={handlePaypay89Click}></img>
                            <img className="withdraw-button" src="/paypay89_button.png" onClick={handlePaypay89Click}></img>
                        </div>
                    )}

                    {modalState === 'enterInformation' && (
                        <div className="deposit-container">
                            <div className="deposit-input">
                                <label>{t('amount')}</label>
                                <input
                                    type="text"
                                    value={amount !== 0 ? amount : ''} // Show empty string if amount is 0
                                    placeholder={amount === 0 ? 'ฝากขั้นต่ำ 100' : ''} // Show placeholder if amount is 0
                                    onChange={(e) => setAmount(parseFloat(e.target.value) || 0)} 
                                />
                            </div>

                            <div className="deposit-add-money-container">
                                <button onClick={() => addMoney(100)}>100</button>
                                <button onClick={() => addMoney(300)}>300</button>
                                <button onClick={() => addMoney(500)}>500</button>
                                <button onClick={() => addMoney(1000)}>1,000</button>
                                <button onClick={() => addMoney(5000)}>5,000</button>
                                <button onClick={() => addMoney(10000)}>10,000</button>
                            </div>
                                
                            <a className="deposit-next-button" onClick={onFinish}>ถัดไป</a>

                        </div>
                    )}

                </div>
            </div>
        </>
    );
};

export default WithdrawModal;
