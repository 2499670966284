import React, { useEffect, useState, useMemo, useContext} from 'react';
import { LoadingOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { format, parseISO, addHours } from 'date-fns';
import { useAuth } from '../../AuthContext';
import './AccountModal.css';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import DataTable from '../../table/DataTable';

const TransactionHistoryModal = ({ visible, onClose, onLogin, onRegisterClick, onCommissionClick, onWheelClick, onInviteFriendClick }) => {
    const { auth} = useAuth();
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0); // Track the current page
    const [size] = useState(10); // Track the page size
    const [totalPages, setTotalPages] = useState(0); // Track the total number of pages
    const apiUrl = process.env.REACT_APP_API_URL;
    const [activeTab, setActiveTab] = useState('all');

    const location = useLocation();
    const { filterType } = location.state || {};

    const { t } = useTranslation();

    const columns = useMemo(() => [
        { Header: t('type'), accessor: 'type' },
        {
            Header: t('amount'),
            accessor: 'amount',
            Cell: ({ row }) => {
                const amount = Number(row.original.amount).toFixed(2);
                const status = row.original.status;
                let icon;

                switch (status) {
                    case 'PENDING':
                        icon = <LoadingOutlined style={{ marginLeft: 8 }} />;
                        break;
                    case 'COMPLETED':
                        icon = <CheckCircleOutlined style={{ color: 'green', marginLeft: 8 }} />;
                        break;
                    case 'FAILED':
                        icon = <CloseCircleOutlined style={{ color: 'red', marginLeft: 8 }} />;
                        break;
                    default:
                        icon = null;
                }

                return (
                    <div>
                        {amount} {icon}
                    </div>
                );
            }
        },
        {
            Header: t('processing_date'),
            accessor: 'createdAt',
            Cell: ({ value }) => {
                // Parse the ISO date string and add 7 hours to convert to UTC+7
                const date = parseISO(value);
                const dateInUTCPlus7 = addHours(date, 7);
                // Format the date as desired
                return format(dateInUTCPlus7, 'yyyy-MM-dd HH:mm:ss');
            }
        }
    ], [t]);

    const fetchTransactions = async (type, page = 0) => {

        setActiveTab(type);

        try {
            let url = `${apiUrl}/member/transaction/get?memberID=${auth.userData.memberId}&page=${page}&size=${size}`;
    
            // Only add the type to the URL if it's not 'all'
            if (type && type !== 'all') {
                url += `&type=${type}`;
            }

            console.log(url);
    
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${auth.token}`,
                    "Content-Type": "application/json"
                },
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const data = await response.json();
            setData(data.content);
            setTotalPages(data.totalPages);
            setPage(page);
            console.log(data);
    
        } catch (error) {
            console.error("Get User DATA failed:", error);
        }
    }

    const handleNextPage = () => {
        if (page < totalPages - 1) {
            setPage(page + 1);
            fetchTransactions(activeTab, page + 1, size);
        }
    };
    
    const handlePreviousPage = () => {
        if (page > 0) {
            setPage(page - 1);
            fetchTransactions(activeTab, page - 1, size);
        }
    };

    useEffect(() => {
        if (auth.userData) {
            if (filterType) {
                fetchTransactions(filterType);
            } else {
                fetchTransactions('all');
            }
        }
    }, [auth, filterType]);

    if (!visible) return null;

    return (
        <>
            <div className="account-modal-container">
                <button className="account-button-close" onClick={onClose}>&times;</button>

                <div className="account-modal-content">
                    <h1>{t('transaction_history')}</h1>

                    <div className="profile-header">
                        <button className={`profile-header-button ${activeTab === 'all' ? 'profile-header-button-active' : ''}`} 
                            onClick={() => fetchTransactions('all')}>{t('all')}</button>
                        <button className={`profile-header-button ${activeTab === 'deposit' ? 'profile-header-button-active' : ''}`} 
                            onClick={() => fetchTransactions('deposit')}>{t('deposit')}</button>
                        <button className={`profile-header-button ${activeTab === 'withdraw' ? 'profile-header-button-active' : ''}`} 
                            onClick={() => fetchTransactions('withdraw')}>{t('withdraw')}</button>
                    </div>

                    <div className="table-input">
                        <DataTable columns={columns} data={data} />
                        <div className="pagination-controls">
                            <button onClick={handlePreviousPage} disabled={page === 0}>{t('previous')}</button>
                            <span>Page {page + 1} of {totalPages}</span>
                            <button onClick={handleNextPage} disabled={page === totalPages - 1}>{t('next')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};

export default TransactionHistoryModal;
