import React, { useState } from 'react';
import './FooterWithProvider.css'; // Import the CSS file

const FooterWithProvider = () => {

  return (
    <div className="footer-provider-container">
      <div className="footer-slider">
        <div className="footer-slide-track">
          <img src="/providers/1.webp" alt="Provider 1"></img>
          <img src="/providers/2.webp" alt="Provider 2"></img>
          <img src="/providers/3.webp" alt="Provider 3"></img>
          <img src="/providers/4.webp" alt="Provider 4"></img>
          <img src="/providers/5.webp" alt="Provider 5"></img>
          <img src="/providers/6.png" alt="Provider 6"></img>
          <img src="/providers/7.webp" alt="Provider 7"></img>
          <img src="/providers/8.webp" alt="Provider 8"></img>
          <img src="/providers/9.webp" alt="Provider 9"></img>
          <img src="/providers/10.webp" alt="Provider 10"></img>
          <img src="/providers/11.webp" alt="Provider 11"></img>
          <img src="/providers/12.webp" alt="Provider 12"></img>
          <img src="/providers/13.webp" alt="Provider 13"></img>
          <img src="/providers/14.webp" alt="Provider 14"></img>
          <img src="/providers/15.webp" alt="Provider 15"></img>
          <img src="/providers/16.webp" alt="Provider 16"></img>
          <img src="/providers/17.webp" alt="Provider 17"></img>
          <img src="/providers/18.webp" alt="Provider 18"></img>
          <img src="/providers/19.webp" alt="Provider 19"></img>
          <img src="/providers/20.webp" alt="Provider 20"></img>
          <img src="/providers/21.webp" alt="Provider 21"></img>
          <img src="/providers/22.webp" alt="Provider 22"></img>
          <img src="/providers/23.webp" alt="Provider 23"></img>
          <img src="/providers/24.webp" alt="Provider 24"></img>
          <img src="/providers/25.webp" alt="Provider 25"></img>
          <img src="/providers/26.webp" alt="Provider 26"></img>
          <img src="/providers/27.webp" alt="Provider 27"></img>
          <img src="/providers/28.webp" alt="Provider 28"></img>
          <img src="/providers/29.webp" alt="Provider 29"></img>
          <img src="/providers/30.webp" alt="Provider 30"></img>
          <img src="/providers/31.webp" alt="Provider 31"></img>
          <img src="/providers/32.webp" alt="Provider 32"></img>
          <img src="/providers/33.webp" alt="Provider 33"></img>
          <img src="/providers/34.webp" alt="Provider 34"></img>
          <img src="/providers/35.webp" alt="Provider 35"></img>
          <img src="/providers/36.webp" alt="Provider 36"></img>
          <img src="/providers/37.webp" alt="Provider 37"></img>
          <img src="/providers/38.webp" alt="Provider 38"></img>
          <img src="/providers/39.webp" alt="Provider 39"></img>
          <img src="/providers/40.webp" alt="Provider 40"></img>
          <img src="/providers/41.webp" alt="Provider 41"></img>
          <img src="/providers/42.webp" alt="Provider 42"></img>
          <img src="/providers/43.webp" alt="Provider 43"></img>
          <img src="/providers/44.webp" alt="Provider 44"></img>
          <img src="/providers/45.webp" alt="Provider 45"></img>
          <img src="/providers/46.webp" alt="Provider 46"></img>
          <img src="/providers/47.webp" alt="Provider 47"></img>
          <img src="/providers/48.webp" alt="Provider 48"></img>
          <img src="/providers/49.webp" alt="Provider 49"></img>
          <img src="/providers/50.webp" alt="Provider 50"></img>
          <img src="/providers/51.webp" alt="Provider 51"></img>
          <img src="/providers/52.webp" alt="Provider 52"></img>
          <img src="/providers/53.webp" alt="Provider 53"></img>
          <img src="/providers/54.webp" alt="Provider 54"></img>
          <img src="/providers/55.webp" alt="Provider 55"></img>
          <img src="/providers/56.webp" alt="Provider 56"></img>
          <img src="/providers/57.webp" alt="Provider 57"></img>
          <img src="/providers/58.webp" alt="Provider 58"></img>
          <img src="/providers/59.webp" alt="Provider 59"></img>
          <img src="/providers/60.webp" alt="Provider 60"></img>
          <img src="/providers/61.webp" alt="Provider 61"></img>
          <img src="/providers/62.webp" alt="Provider 62"></img>
          <img src="/providers/63.webp" alt="Provider 63"></img>
          <img src="/providers/64.webp" alt="Provider 64"></img>
          <img src="/providers/65.webp" alt="Provider 65"></img>
          <img src="/providers/66.webp" alt="Provider 66"></img>
          <img src="/providers/67.webp" alt="Provider 67"></img>
          <img src="/providers/68.webp" alt="Provider 68"></img>
          <img src="/providers/69.webp" alt="Provider 69"></img>
          <img src="/providers/70.webp" alt="Provider 70"></img>
          <img src="/providers/71.webp" alt="Provider 71"></img>
          <img src="/providers/72.webp" alt="Provider 72"></img>
          <img src="/providers/73.webp" alt="Provider 73"></img>
          <img src="/providers/74.webp" alt="Provider 74"></img>
          <img src="/providers/75.webp" alt="Provider 75"></img>
          <img src="/providers/76.webp" alt="Provider 76"></img>
          <img src="/providers/77.webp" alt="Provider 77"></img>
          <img src="/providers/78.webp" alt="Provider 78"></img>
          <img src="/providers/79.webp" alt="Provider 79"></img>
          <img src="/providers/80.webp" alt="Provider 80"></img>
          <img src="/providers/81.webp" alt="Provider 81"></img>
          <img src="/providers/82.webp" alt="Provider 82"></img>
          <img src="/providers/83.webp" alt="Provider 83"></img>
          <img src="/providers/84.webp" alt="Provider 84"></img>
          <img src="/providers/85.webp" alt="Provider 85"></img>
          <img src="/providers/86.webp" alt="Provider 86"></img>
          <img src="/providers/87.webp" alt="Provider 87"></img>
          <img src="/providers/88.webp" alt="Provider 88"></img>
          <img src="/providers/89.webp" alt="Provider 89"></img>
          <img src="/providers/90.webp" alt="Provider 90"></img>
          <img src="/providers/91.webp" alt="Provider 91"></img>
          <img src="/providers/92.webp" alt="Provider 92"></img>
          <img src="/providers/93.webp" alt="Provider 93"></img>
          <img src="/providers/94.webp" alt="Provider 94"></img>
          <img src="/providers/95.webp" alt="Provider 95"></img>
          <img src="/providers/96.webp" alt="Provider 96"></img>
          <img src="/providers/97.webp" alt="Provider 97"></img>
          <img src="/providers/98.webp" alt="Provider 98"></img>
          <img src="/providers/99.webp" alt="Provider 99"></img>

          <img src="/providers/1.webp" alt="Provider 1"></img>
          <img src="/providers/2.webp" alt="Provider 2"></img>
          <img src="/providers/3.webp" alt="Provider 3"></img>
          <img src="/providers/4.webp" alt="Provider 4"></img>
          <img src="/providers/5.webp" alt="Provider 5"></img>
          <img src="/providers/6.png" alt="Provider 6"></img>
          <img src="/providers/7.webp" alt="Provider 7"></img>
          <img src="/providers/8.webp" alt="Provider 8"></img>
          <img src="/providers/9.webp" alt="Provider 9"></img>
          <img src="/providers/10.webp" alt="Provider 10"></img>
          <img src="/providers/11.webp" alt="Provider 11"></img>
          <img src="/providers/12.webp" alt="Provider 12"></img>
          <img src="/providers/13.webp" alt="Provider 13"></img>
          <img src="/providers/14.webp" alt="Provider 14"></img>
          <img src="/providers/15.webp" alt="Provider 15"></img>
          <img src="/providers/16.webp" alt="Provider 16"></img>
          <img src="/providers/17.webp" alt="Provider 17"></img>
          <img src="/providers/18.webp" alt="Provider 18"></img>
          <img src="/providers/19.webp" alt="Provider 19"></img>
          <img src="/providers/20.webp" alt="Provider 20"></img>
          <img src="/providers/21.webp" alt="Provider 21"></img>
          <img src="/providers/22.webp" alt="Provider 22"></img>
          <img src="/providers/23.webp" alt="Provider 23"></img>
          <img src="/providers/24.webp" alt="Provider 24"></img>
          <img src="/providers/25.webp" alt="Provider 25"></img>
          <img src="/providers/26.webp" alt="Provider 26"></img>
          <img src="/providers/27.webp" alt="Provider 27"></img>
          <img src="/providers/28.webp" alt="Provider 28"></img>
          <img src="/providers/29.webp" alt="Provider 29"></img>
          <img src="/providers/30.webp" alt="Provider 30"></img>
          <img src="/providers/31.webp" alt="Provider 31"></img>
          <img src="/providers/32.webp" alt="Provider 32"></img>
          <img src="/providers/33.webp" alt="Provider 33"></img>
          <img src="/providers/34.webp" alt="Provider 34"></img>
          <img src="/providers/35.webp" alt="Provider 35"></img>
          <img src="/providers/36.webp" alt="Provider 36"></img>
          <img src="/providers/37.webp" alt="Provider 37"></img>
          <img src="/providers/38.webp" alt="Provider 38"></img>
          <img src="/providers/39.webp" alt="Provider 39"></img>
          <img src="/providers/40.webp" alt="Provider 40"></img>
          <img src="/providers/41.webp" alt="Provider 41"></img>
          <img src="/providers/42.webp" alt="Provider 42"></img>
          <img src="/providers/43.webp" alt="Provider 43"></img>
          <img src="/providers/44.webp" alt="Provider 44"></img>
          <img src="/providers/45.webp" alt="Provider 45"></img>
          <img src="/providers/46.webp" alt="Provider 46"></img>
          <img src="/providers/47.webp" alt="Provider 47"></img>
          <img src="/providers/48.webp" alt="Provider 48"></img>
          <img src="/providers/49.webp" alt="Provider 49"></img>
          <img src="/providers/50.webp" alt="Provider 50"></img>
          <img src="/providers/51.webp" alt="Provider 51"></img>
          <img src="/providers/52.webp" alt="Provider 52"></img>
          <img src="/providers/53.webp" alt="Provider 53"></img>
          <img src="/providers/54.webp" alt="Provider 54"></img>
          <img src="/providers/55.webp" alt="Provider 55"></img>
          <img src="/providers/56.webp" alt="Provider 56"></img>
          <img src="/providers/57.webp" alt="Provider 57"></img>
          <img src="/providers/58.webp" alt="Provider 58"></img>
          <img src="/providers/59.webp" alt="Provider 59"></img>
          <img src="/providers/60.webp" alt="Provider 60"></img>
          <img src="/providers/61.webp" alt="Provider 61"></img>
          <img src="/providers/62.webp" alt="Provider 62"></img>
          <img src="/providers/63.webp" alt="Provider 63"></img>
          <img src="/providers/64.webp" alt="Provider 64"></img>
          <img src="/providers/65.webp" alt="Provider 65"></img>
          <img src="/providers/66.webp" alt="Provider 66"></img>
          <img src="/providers/67.webp" alt="Provider 67"></img>
          <img src="/providers/68.webp" alt="Provider 68"></img>
          <img src="/providers/69.webp" alt="Provider 69"></img>
          <img src="/providers/70.webp" alt="Provider 70"></img>
          <img src="/providers/71.webp" alt="Provider 71"></img>
          <img src="/providers/72.webp" alt="Provider 72"></img>
          <img src="/providers/73.webp" alt="Provider 73"></img>
          <img src="/providers/74.webp" alt="Provider 74"></img>
          <img src="/providers/75.webp" alt="Provider 75"></img>
          <img src="/providers/76.webp" alt="Provider 76"></img>
          <img src="/providers/77.webp" alt="Provider 77"></img>
          <img src="/providers/78.webp" alt="Provider 78"></img>
          <img src="/providers/79.webp" alt="Provider 79"></img>
          <img src="/providers/80.webp" alt="Provider 80"></img>
          <img src="/providers/81.webp" alt="Provider 81"></img>
          <img src="/providers/82.webp" alt="Provider 82"></img>
          <img src="/providers/83.webp" alt="Provider 83"></img>
          <img src="/providers/84.webp" alt="Provider 84"></img>
          <img src="/providers/85.webp" alt="Provider 85"></img>
          <img src="/providers/86.webp" alt="Provider 86"></img>
          <img src="/providers/87.webp" alt="Provider 87"></img>
          <img src="/providers/88.webp" alt="Provider 88"></img>
          <img src="/providers/89.webp" alt="Provider 89"></img>
          <img src="/providers/90.webp" alt="Provider 90"></img>
          <img src="/providers/91.webp" alt="Provider 91"></img>
          <img src="/providers/92.webp" alt="Provider 92"></img>
          <img src="/providers/93.webp" alt="Provider 93"></img>
          <img src="/providers/94.webp" alt="Provider 94"></img>
          <img src="/providers/95.webp" alt="Provider 95"></img>
          <img src="/providers/96.webp" alt="Provider 96"></img>
          <img src="/providers/97.webp" alt="Provider 97"></img>
          <img src="/providers/98.webp" alt="Provider 98"></img>
          <img src="/providers/99.webp" alt="Provider 99"></img>
        </div>
      </div>
    </div>
  );
};

export default FooterWithProvider;