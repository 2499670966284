import React, { useState, useContext } from 'react';
import { useAuth } from '../../AuthContext';
import './AccountModal.css';
import './RetrieveBonusModal.css';
import { useTranslation } from 'react-i18next';

const RetrieveBonusModal = ({ visible, onClose, onLogin, onRegisterClick, onCommissionClick, onWheelClick, onInviteFriendClick }) => {
  const { auth} = useAuth();
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const { t } = useTranslation();

  if (!visible) return null;

  return (
    <>
        <div className="account-modal-container">
            <button className="account-button-close" onClick={onClose}>&times;</button>

            <div className="account-modal-content">
                <img className="modal-title-image" src="/commission_temp.png"></img>
                <h1>{t('retrieve_bonus')}</h1>

                <div className="get-bonus-container">
                    <h2>โบนัสคืนเงินยอดเดิมพัน</h2>
                    <p>ท่านมีโบนัสคืนเงินเดิมพันประจำสัปดาห์จำนวน</p>
                    <h3>498.50 บาท</h3>
                    <button>รับเลย</button>
                </div>

                <div className="get-bonus-container">
                    <h2>โบนัสคืนเงินยอดเสีย</h2>
                    <p>ท่านมีโบนัสคืนเงินยอดเสียประจำสัปดาห์จำนวน</p>
                    <h3>1,350.50 บาท</h3>
                    <button>รับเลย</button>
                </div>
            </div>
        </div>
    </>

  );
};

export default RetrieveBonusModal;
