import React, { useState, useContext } from 'react';
import './SelectType.css'; // Import the CSS file
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../ThemeContext';

const SelectType = ({onSlotClick, onCasinoClick, onSportClick, onCardsClick, onFishingClick, 
                     onPokerClick, onKenoClick, onTradingClick, onEsportClick, onCockClick}) => {

  const { t } = useTranslation();

  const [selectedType, setSelectedType] = useState('SLOT');

  const { theme } = useContext(ThemeContext);

  const handleSlotClick = () => {
    setSelectedType("SLOT");
    onSlotClick();
  }

  const handleCasinoClick = () => {
    setSelectedType("CASINO");
    onCasinoClick();
  }

  const handleSportClick = () => {
    setSelectedType("SPORT");
    onSportClick();
  }

  const handleCardsClick = () => {
    setSelectedType("CARD");
    onCardsClick();
  }

  const handleFishingClick = () => {
    setSelectedType("FISH");
    onFishingClick()
  }

  const handlePokerClick = () => {
    setSelectedType("POKER");
    onPokerClick();
  }

  const handleKenoClick = () => {
    setSelectedType("KENO");
    onKenoClick();
  }

  const handleTradingClick = () => {
    setSelectedType("TRADE");
    onTradingClick();
  }

  const handleEsportClick = () => {
    setSelectedType("ESPORT");
    onEsportClick();
  }

  const handleCockClick = () => {
    setSelectedType("COCK");
    onCockClick();
  }

  return (
    <div className="select-type-container">
        <div className={`select-type-button-container ${selectedType === 'SLOT' ? 'active' : ''}`} onClick={handleSlotClick}>
          <img className="select-type-button-image" src={theme === 'light' ? '/slot_light.png' : '/slot_3d.png'} alt="Slot"></img>
          <p className={`select-type-button-text ${selectedType === 'SLOT' ? 'active' : ''}`}>{t('slot')}</p>
        </div>

        <div className={`select-type-button-container ${selectedType === 'CASINO' ? 'active' : ''}`} onClick={handleCasinoClick}>
          <img className="select-type-button-image" src={theme === 'light' ? '/casino_light.png' : '/casino_3d.png'} alt="Casino"></img>
          <p className={`select-type-button-text ${selectedType === 'CASINO' ? 'active' : ''}`}>{t('casino')}</p>
        </div>

        <div className={`select-type-button-container ${selectedType === 'SPORT' ? 'active' : ''}`} onClick={handleSportClick}>
          <img className="select-type-button-image" src={theme === 'light' ? '/sports_light.png' : '/sports_3d.png'} alt="Sport"></img>
          <p className={`select-type-button-text ${selectedType === 'SPORT' ? 'active' : ''}`}>{t('sports')}</p>
        </div>

        <div className={`select-type-button-container ${selectedType === 'CARD' ? 'active' : ''}`} onClick={handleCardsClick}>
          <img className="select-type-button-image" src={theme === 'light' ? '/cards_light.png' : '/cards_3d.png'} alt="ไพ่"></img>
          <p className={`select-type-button-text ${selectedType === 'CARD' ? 'active' : ''}`}>{t('cards')}</p>
        </div>

        <div className={`select-type-button-container ${selectedType === 'FISH' ? 'active' : ''}`} onClick={handleFishingClick}>
          <img className="select-type-button-image" src={theme === 'light' ? '/fishing_light.png' : '/fishing_3d.png'} alt="เกมตกปลา"></img>
          <p className={`select-type-button-text ${selectedType === 'FISH' ? 'active' : ''}`}>{t('fishing')}</p>
        </div>

        <div className={`select-type-button-container ${selectedType === 'POKER' ? 'active' : ''}`} onClick={handlePokerClick}>
          <img className="select-type-button-image" src={theme === 'light' ? '/poker_light.png' : '/poker_3d.png'} alt="โป๊กเกอร์"></img>
          <p className={`select-type-button-text ${selectedType === 'POKER' ? 'active' : ''}`}>{t('poker')}</p>
        </div>

        <div className={`select-type-button-container ${selectedType === 'KENO' ? 'active' : ''}`} onClick={handleKenoClick}>
          <img className="select-type-button-image" src={theme === 'light' ? '/keno_light.png' : '/keno_3d.png'} alt="คีโน่"></img>
          <p className={`select-type-button-text ${selectedType === 'KENO' ? 'active' : ''}`}>{t('keno')}</p>
        </div>

        <div className={`select-type-button-container ${selectedType === 'TRADE' ? 'active' : ''}`} onClick={handleTradingClick}>
          <img className="select-type-button-image" src={theme === 'light' ? '/trade_light.png' : '/trade_3d.png'} alt="เทรดดิ้ง"></img>
          <p className={`select-type-button-text ${selectedType === 'TRADE' ? 'active' : ''}`}>{t('trading')}</p>
        </div>

        <div className={`select-type-button-container ${selectedType === 'ESPORT' ? 'active' : ''}`} onClick={handleEsportClick}>
          <img className="select-type-button-image" src={theme === 'light' ? '/esports_light.png' : '/esports_3d.png'} alt="Esport"></img>
          <p className={`select-type-button-text ${selectedType === 'ESPORT' ? 'active' : ''}`}>{t('esports')}</p>
        </div>

        <div className={`select-type-button-container ${selectedType === 'COCK' ? 'active' : ''}`} onClick={handleCockClick}>
          <img className="select-type-button-image" src={theme === 'light' ? '/cock_light.png' : '/cock_3d.png'} alt="ไก่ชน"></img>
          <p className={`select-type-button-text ${selectedType === 'COCK' ? 'active' : ''}`}>{t('cockfight')}</p>
        </div>
    </div>
  );
};

export default SelectType;