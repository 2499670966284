import React, { useEffect, useState } from 'react';
import HeaderWithMenu from '../components/HeaderWithMenu';
import MenuModal from '../components/MenuModal';
import { useAuth } from '../AuthContext';
import './ProfilePage.css';
import PageMark from '../components/ฺPageMark';
import { useNavigate } from 'react-router-dom';
import { Select , message } from 'antd';
import { useTranslation } from 'react-i18next';
import CommissionModal from '../components/CommissionModal';
import WheelGameModal from '../components/WheelGameModal';

function ManageBankAdd() {
  const [isMenuModalVisible, setIsMenuModalVisible] = useState(false);
  const [isCommissionModalVisible, setIsCommissionModalVisible] = useState(false);
  const [isWheelModalVisible, setIsWheelModalVisible] = useState(false);

  const showMenuModal = () => setIsMenuModalVisible(true);
  const cancelMenuModal = () => setIsMenuModalVisible(false);
  
  const showCommissionModal = () =>  setIsCommissionModalVisible(true);
  const cancelCommissionModal = () => setIsCommissionModalVisible(false);

  const showWheelModal = () =>  setIsWheelModalVisible(true);
  const cancelWheelModal = () => setIsWheelModalVisible(false);

  const [selectedBankName, setSelectedBankName] = useState('');
  const [bankNumber, setBankNumber] = useState('');
  const [fullName, setFullName] = useState('');
  
  const [userData, setUserData] = useState([]);
  const [isBankAccountAvailable, setIsBankAccountAvailable] = useState(false);
  const { auth } = useAuth();

  const { t } = useTranslation();

  const navigate = useNavigate(); // Initialize the useNavigate hook

  const handleAccountClick = () => navigate('/profile');
  const handleDepositClick = () => navigate('/deposit');
  const handleWithdrawClick = () => navigate('/withdraw');
  const handleTransactionHistoryClick = () => navigate('/transaction-history');
  const handlePromotionHistoryClick = () => navigate('/promotion-history');
  const handleInviteFriendClick = () => navigate('/invite-friend');

  const handleAddAccount = async() => {

    console.log(auth.userData.bankAccounts);
    if (!selectedBankName) {
      message.error('Please select a bank account first.');
      return;
    }

    const values = {
      memberId: auth.userData.memberId,
      bank: selectedBankName,
      accountNumber: bankNumber,
      fullname: fullName
    };

    console.log('Form values:', JSON.stringify(values));        

    const method = "POST";

    var apiUrl = `${process.env.REACT_APP_API_URL}/member/bankaccount/add`;
    
    const requestOptions = {
        method,
        headers: { "Authorization": `Bearer ${auth.token}`, "Content-Type": "application/json" },
        body: JSON.stringify(values)
    };

    try {
        const response = await fetch(apiUrl, requestOptions);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        message.success('Bank account added successfully!');
        navigate(`/profile`);
        window.location.reload();
    } catch (error) {
        console.error('Error posting member:', error);
    }
  };

  useEffect(() => {
      if (auth.userData) {
        setUserData(auth.userData); // Directly set the bankAccounts array
        setIsBankAccountAvailable(auth.userData.bankAccounts.length > 0);
      }
  }, [auth]);

  if (!auth.userData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="background-element">
      <HeaderWithMenu onMenuClick={showMenuModal} />

      <div className="profile-content-container">
        <img className="profile-content-background" src="/profile_background.png"></img>

        <div className="profile-content">
          <div className="profile-header">
            <button className="profile-header-button-active" onClick={handleAccountClick}>{t('account')}</button>
            <button className="profile-header-button" onClick={handleDepositClick}>{t('deposit')}</button>
            <button className="profile-header-button" onClick={handleWithdrawClick}>{t('withdraw')}</button>
            <button className="profile-header-button" onClick={handleTransactionHistoryClick}>{t('transaction_history')}</button>
            <button className="profile-header-button" onClick={handlePromotionHistoryClick}>{t('promotion_history')}</button>
            <button className="profile-header-button" onClick={handleInviteFriendClick}>{t('invite_friend')}</button>
          </div>

          <PageMark />

          <div className="profile-input-container">
            <h2>จัดการบัญชี</h2>

            <div className="profile-input">
                <label>Balance</label>
                <Select placeholder="Select Bank Name" onSelect={(value) => setSelectedBankName(value)}>
                    <Select.Option value="SCB">Siam Commercial Bank</Select.Option>
                    <Select.Option value="KBANK">Kasikorn Bank</Select.Option>
                    <Select.Option value="KTB">Krung Thai Bank</Select.Option>
                    <Select.Option value="BBL">Bangkok Bank</Select.Option>
                </Select>
            </div>

            <div className="add-bank-input">
              <label>หมายเลขบัญชีธนาคาร</label>
              <input
                type="text"
                placeholder='หมายเลขบัญชีธนาคาร'
                onChange={(e) => setBankNumber(e.target.value)} 
              />
            </div>

            <div className="add-bank-input">
              <label>ชื่อเจ้าของบัญชี</label>
              <input
                type="text"
                placeholder='ชื่อเจ้าของบัญชี'
                onChange={(e) => setFullName(e.target.value)} 
              />
            </div>
            
            <div className="add-edit-bank-container">
              <a className="profile-add-bank" onClick={handleAddAccount}>เพิ่ม</a>
            </div>
          </div>
        </div>
      </div>
        
      
      <MenuModal visible={isMenuModalVisible} onClose={cancelMenuModal} onCommissionClick={showCommissionModal} onWheelClick={showWheelModal} />
      <CommissionModal visible={isCommissionModalVisible} onClose={cancelCommissionModal} />
      <WheelGameModal visible={isWheelModalVisible} onClose={cancelWheelModal} />
    </div>
  );
}

export default ManageBankAdd;