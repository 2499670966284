// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainPage from './pages/MainPage';
import PromotionPage from './pages/PromotionPage';
import RankingPage from './pages/RankingPage';
import ManageBank from './pages/ManageBank';
import ManageBankAdd from './pages/ManageBankAdd';
import ManageBankEdit from './pages/ManageBankEdit';
import './styles/App.css';
import InviteFriend from './pages/InviteFriend';
import WheelPage from './pages/WheelPage';
import { ThemeProvider } from './ThemeContext';  // Import the ThemeProvider


function App() {
  return (
    <ThemeProvider>
      <Router>
        <Routes>
          <Route path="/" element={<MainPage/>} />
          <Route path="/promotion" element={<PromotionPage/>} />
          <Route path="/ranking" element={<RankingPage/>} />
          <Route path="/invite" element={<InviteFriend/>} />
          <Route path="/wheel" element={<WheelPage/>} />
          <Route path="/manage-bank" element={<ManageBank/>} />
          <Route path="/manage-bank/add" element={<ManageBankAdd/>} />
          <Route path="/manage-bank/edit/:id" element={<ManageBankEdit/>}/>
          {/* <Route path="/deposit/success" element={<DepositSuccess/>}/>
          <Route path="/deposit/failed" element={<DepositFailed/>}/> */}
          {/* <Route path="/withdraw/success" element={<WithdrawSuccess/>}/>
          <Route path="/withdraw/failed" element={<WithdrawFailed/>}/> */}
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;