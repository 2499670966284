import React, { useEffect, useState } from 'react';
import HeaderWithMenu from '../components/HeaderWithMenu';
import MenuModal from '../components/MenuModal';
import { useAuth } from '../AuthContext';
import './ProfilePage.css';
import PageMark from '../components/ฺPageMark';
import { useNavigate } from 'react-router-dom';
import { Select , message } from 'antd';
import { useTranslation } from 'react-i18next';
import CommissionModal from '../components/CommissionModal';
import WheelGameModal from '../components/WheelGameModal';

function ProfilePage() {
  const [isMenuModalVisible, setIsMenuModalVisible] = useState(false);
  const [isCommissionModalVisible, setIsCommissionModalVisible] = useState(false);
  const [isWheelModalVisible, setIsWheelModalVisible] = useState(false);

  const showMenuModal = () => setIsMenuModalVisible(true);
  const cancelMenuModal = () => setIsMenuModalVisible(false);

  const showCommissionModal = () =>  setIsCommissionModalVisible(true);
  const cancelCommissionModal = () => setIsCommissionModalVisible(false);

  const showWheelModal = () =>  setIsWheelModalVisible(true);
  const cancelWheelModal = () => setIsWheelModalVisible(false);

  const [selectedBankAccount, setSelectedBankAccount] = useState(0);

  const [userData, setUserData] = useState([]);
  const [isBankAccountAvailable, setIsBankAccountAvailable] = useState(false);
  const { auth } = useAuth();

  const { t } = useTranslation();

  const navigate = useNavigate(); // Initialize the useNavigate hook

  const handleAccountClick = () => navigate('/profile');
  const handleDepositClick = () => navigate('/deposit');
  const handleWithdrawClick = () => navigate('/withdraw');
  const handleTransactionHistoryClick = () => navigate('/transaction-history');
  const handlePromotionHistoryClick = () => navigate('/promotion-history');
  const handleInviteFriendClick = () => navigate('/invite-friend');

  const handleEdit = () => {
    navigate(`/manage-bank/edit/${selectedBankAccount}`);
  };

  const handleAddAccount = () => {
    navigate(`/manage-bank/add`);
  };

  const changeMainBankAccount = async() => {

    console.log(auth.userData.bankAccounts);
    if (!selectedBankAccount) {
      message.error('Please select a bank account first.');
      return;
    }

    const values = {
      memberId: auth.userData.memberId,
      bankAccountId: selectedBankAccount
    };

    console.log('Form values:', JSON.stringify(values));

    const method = "PUT";

    var apiUrl = `${process.env.REACT_APP_API_URL}/member/bankaccount/setmain`;
    
    const requestOptions = {
        method,
        headers: { "Authorization": `Bearer ${auth.token}`, "Content-Type": "application/json" },
        body: JSON.stringify(values)
    };

    try {
      const response = await fetch(apiUrl, requestOptions);
      if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      message.success('Bank account set successfully!');
      navigate('/profile');
      window.location.reload();
    } catch (error) {
        console.error('Error posting member:', error);
    }
  }

  useEffect(() => {
      if (auth.userData) {
        setUserData(auth.userData); // Directly set the bankAccounts array
        setIsBankAccountAvailable(auth.userData.bankAccounts.length > 0);
      }
  }, [auth]);

  if (!auth.userData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="background-element">
      <HeaderWithMenu onMenuClick={showMenuModal} />

      <div className="profile-content-container">
        <img className="profile-content-background" src="/profile_background.png"></img>

        <div className="profile-content">
          <div className="profile-header">
            <button className="profile-header-button-active" onClick={handleAccountClick}>{t('account')}</button>
            <button className="profile-header-button" onClick={handleDepositClick}>{t('deposit')}</button>
            <button className="profile-header-button" onClick={handleWithdrawClick}>{t('withdraw')}</button>
            <button className="profile-header-button" onClick={handleTransactionHistoryClick}>{t('transaction_history')}</button>
            <button className="profile-header-button" onClick={handlePromotionHistoryClick}>{t('promotion_history')}</button>
            <button className="profile-header-button" onClick={handleInviteFriendClick}>{t('invite_friend')}</button>
          </div>

          <PageMark />

          <div className="profile-input-container">
            <h2>{t('manage_bank_account')}</h2>
            <div className="profile-input">

              <label>{t('select_main_bank_account')}</label>
              <Select placeholder="Select Bank Account" onSelect={(value) => setSelectedBankAccount(value)} disabled={!isBankAccountAvailable}>
              {isBankAccountAvailable ? (
                  userData.bankAccounts.map(account => (
                  <Select.Option
                      key={account.id}
                      value={account.id}
                  >
                      {account.bankName} - {account.accountNumber} - {account.fullName}
                  </Select.Option>
                  ))
              ) : (
                  <Select.Option key={0} value={0} disabled>Loading bank accounts...</Select.Option>
              )}
              </Select>
            </div>
            
            <div className="add-edit-bank-container">
              {isBankAccountAvailable ? 
              <a className="profile-submit-bank" style={{marginRight: '10px'}} onClick={changeMainBankAccount}>ยืนยัน</a> 
              : (<></>)
              }
              
              <a className="profile-add-bank" onClick={handleAddAccount}>เพิ่ม</a>
              {selectedBankAccount != 0 ? 
              <a className="profile-edit-bank" style={{marginLeft: '10px'}} onClick={handleEdit}>แก้ไข</a>
              : (<></>)
              }
            </div>
          </div>
        </div>
      </div>
        
      
      <MenuModal visible={isMenuModalVisible} onClose={cancelMenuModal} onCommissionClick={showCommissionModal} onWheelClick={showWheelModal} />
      <CommissionModal visible={isCommissionModalVisible} onClose={cancelCommissionModal} />
      <WheelGameModal visible={isWheelModalVisible} onClose={cancelWheelModal} />
    </div>
  );
}

export default ProfilePage;