import React, { useState, useEffect } from 'react';
import HeaderWithMenu from '../components/HeaderWithMenu';
import FooterWithProvider from '../components/FooterWithProvider';
import FooterWithCopyright from '../components/FooterWithCopyright';
import FooterWithButton from '../components/FooterWithButton';
import AnnouncementBar from '../components/AnnouncementBar';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import LoginModal from '../components/LoginModal';
import RegisterModal from '../components/RegisterModal';
import MenuModal from '../components/MenuModal';
import AccountSidebar from '../components/AccountSidebar';

import AccountModal from '../components/AccountSection/AccountModal';

import DepositModal from '../components/AccountSection/DepositModal';
import WithdrawModal from '../components/AccountSection/WithdrawModal';
import TransactionHistoryModal from '../components/AccountSection/TransactionHistoryModal';
import PromotionHistoryModal from '../components/AccountSection/PromotionHistoryModal';
import RetrieveBonusModal from '../components/AccountSection/RetrieveBonusModal';
import CommissionModal from '../components/CommissionModal';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { useTranslation } from 'react-i18next';

import './InviteFriend.css';

function InviteFriend() {
  const appURL = process.env.REACT_APP_URL;

  const navigate = useNavigate();

  const { auth } = useAuth();

  const { t } = useTranslation();

  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [isRegisterModalVisible, setIsRegisterModalVisible] = useState(false);
  const [isMenuModalVisible, setIsMenuModalVisible] = useState(false);
  const [isAccountSidebarVisible, setIsAccountSidebarVisible] = useState(false);
  
  const [isAccountModalVisible, setIsAccountModalVisible] = useState(false);
  const [isDepositModalVisible, setIsDepositModalVisible] = useState(false);
  const [isWithdrawModalVisible, setIsWithdrawModalVisible] = useState(false);
  const [isTransactionHistoryModalVisible, setIsTransactionHistoryModalVisible] = useState(false);
  const [isPromotionHistoryModalVisible, setIsPromotionHistoryModalVisible] = useState(false);
  const [isRetrieveBonusModalVisible, setIsRetrieveBonusModalVisible] = useState(false);
  
  const [isCommissionModalVisible, setIsCommissionModalVisible] = useState(false);

  const [inputValue, setInputValue] = useState('');

  const showLoginModal = () => setIsLoginModalVisible(true);
  const cancelLoginModal = () => setIsLoginModalVisible(false);

  const showRegisterModal = () => setIsRegisterModalVisible(true);
  const cancelRegisterModal = () => setIsRegisterModalVisible(false);

  const showMenuModal = () => setIsMenuModalVisible(true);
  const cancelMenuModal = () => setIsMenuModalVisible(false);

  const showAccountSidebar = () => setIsAccountSidebarVisible(true);
  const cancelAccountSidebar = () => setIsAccountSidebarVisible(false);

  const showAccountModal = () => setIsAccountModalVisible(true);
  const cancelAccountModal = () => setIsAccountModalVisible(false);

  const showDepositModal = () => setIsDepositModalVisible(true);
  const cancelDepositModal = () => setIsDepositModalVisible(false);

  const showWithdrawModal = () => setIsWithdrawModalVisible(true);
  const cancelWithdrawModal = () => setIsWithdrawModalVisible(false);

  const showTransactionHistoryModal = () => setIsTransactionHistoryModalVisible(true);
  const cancelTransactionHistoryModal = () => setIsTransactionHistoryModalVisible(false);

  const showPromotionHistoryModal = () => setIsPromotionHistoryModalVisible(true);
  const cancelPromotionHistoryModal = () => setIsPromotionHistoryModalVisible(false);

  const showRetrieveBonusModal = () => setIsRetrieveBonusModalVisible(true);
  const cancelRetrieveBonusModal = () => setIsRetrieveBonusModalVisible(false);

  const showCommissionModal = () =>  setIsCommissionModalVisible(true);
  const cancelCommissionModal = () => setIsCommissionModalVisible(false);

  const handleGameClick = () => navigate("/");

  useEffect(() => {
    if (auth.isAuthenticated && auth.userData) {
      let affiliateUrl = process.env.REACT_APP_URL+"?"+auth.userData.ownedAffiliate.code;
      setInputValue(affiliateUrl);
    }
  }, [auth]);

  const handleCopy = () => {
    navigator.clipboard.writeText(inputValue).then(() => {
        alert('Copied to clipboard!');
    }).catch(err => {
        alert('Failed to copy!');
    });
  };

  return (
    <div className="ranking-background-element">
      <HeaderWithMenu onLoginClick={showLoginModal} onMenuClick={showMenuModal} onProfileClick={showAccountSidebar}/>
      <AnnouncementBar />
      <div className="invite-friend-content-container">
        <h1>Invite Friend</h1>

        <label>{t('get_link_invite_friend')}</label>
        <input
          type="text"
          value={appURL+"?"+auth?.userData?.ownedAffiliate?.code ?? ''}
          disabled
        />
        <button onClick={handleCopy}>{t('copy')}</button>

        <img src="invite_friend_image_0.png"></img>
        <h2>รับเงินได้ทุกวัน</h2>
        <h2>Passive Income ที่แท้จริง</h2>
        <img className="invite-friend-image" src="invite_friend_image_1.webp"></img>
        <h2>ทำมาแล้ว!</h2>
        <h2>แค่เพื่อนเล่น เราก็รับทรัพย์</h2>
        <h2>รายได้ 6 หลัก ง่ายๆ ทุกเดือน</h2>
        <img className="invite-friend-image" src="invite_friend_image_2.webp"></img>
        <h3>ส่วนแบ่งจากการแนะนำ สามารถนำไปเดิมพันต่อ หรือ ถอนได้เลย ไม่มีเทิร์นโอเวอร์ ไม่จำกัดยอด สร้างรายได้กับเราได้ทันที ชวนเลย เพื่อนสายบาคาร่า เพื่อนสายสล็อต เพื่อนสายกีฬา เพื่อนสายยิงปลา เพื่อนสายหวย ไม่ว่าสายไหนก็ชวนเพื่อนได้ไม่จำกัด รับส่วนแบ่งค่าคอมมิชชั่นแบบเน้นๆ จ่ายกันแบบรายวัน อัพเดทยอดของเพื่อนคุณแบบเรียลไทม์ แค่เพื่อนเล่น คุณก็มีรายได้ไม่จำกัด</h3>
        <img className="invite-friend-image" src="invite_friend_image_3.png"></img>
      </div>
      
      <FooterWithProvider />
      <FooterWithCopyright />
      <FooterWithButton onRegisterClick={showRegisterModal} onLogin={showLoginModal} onHomeClick={handleGameClick}/>

      <LoginModal visible={isLoginModalVisible} onClose={cancelLoginModal} onRegisterClick={showRegisterModal} />
      <RegisterModal visible={isRegisterModalVisible} onClose={cancelRegisterModal} onLogin={showLoginModal}/>
      <MenuModal visible={isMenuModalVisible} onClose={cancelMenuModal} onLogin={showLoginModal} onRegisterClick={showRegisterModal} onCommissionClick={showCommissionModal}/>
      <AccountSidebar visible={isAccountSidebarVisible} onClose={cancelAccountSidebar} onAccountClick={showAccountModal} onDepositClick={showDepositModal} onWithdrawClick={showWithdrawModal} onTransactionHistoryClick={showTransactionHistoryModal} onPromotionHistoryClick={showPromotionHistoryModal} onGetBonusClick={showRetrieveBonusModal}/>
      
      <AccountModal visible={isAccountModalVisible} onClose={cancelAccountModal}/>
      <DepositModal visible={isDepositModalVisible} onClose={cancelDepositModal}/>
      <WithdrawModal visible={isWithdrawModalVisible} onClose={cancelWithdrawModal}/>
      <TransactionHistoryModal visible={isTransactionHistoryModalVisible} onClose={cancelTransactionHistoryModal}/>
      <PromotionHistoryModal visible={isPromotionHistoryModalVisible} onClose={cancelPromotionHistoryModal}/>
      <RetrieveBonusModal visible={isRetrieveBonusModalVisible} onClose={cancelRetrieveBonusModal}/>

      <CommissionModal visible={isCommissionModalVisible} onClose={cancelCommissionModal} />
    </div>
  );
}

export default InviteFriend;
