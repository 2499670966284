import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthContext';
import './AccountSidebar.css';
import { useTranslation } from 'react-i18next';
import { ReloadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const AccountSidebar = ({ visible, onClose, onAccountClick, onDepositClick ,onWithdrawClick, onTransactionHistoryClick, onPromotionHistoryClick, onGetBonusClick, onInviteFriendClick }) => {

    const { auth , logout } = useAuth();

    const { t } = useTranslation();

    const navigate = useNavigate();

    const handleCloseClick = () => {
        onClose();
    }

    const handleAccountClick = () => {
        onAccountClick();
        onClose();
    }

    const handleDepositClick = () => {
        onDepositClick();
        onClose();
    }

    const handleWithdrawClick = () => {
        onWithdrawClick();
        onClose();
    }

    const handleTransactionHistoryClick = () => {
        onTransactionHistoryClick();
        onClose();
    }

    const handlePromotionHistoryClick = () => {
        onPromotionHistoryClick();
        onClose();
    }

    const handleGetBonusClick = () => {
        onGetBonusClick();
        onClose();
    }

    const handleInviteFriendClick = () => {
        navigate('/invite');
    }

    const handleLogoutClick = () => {
        logout();
        onClose();
    }
    
    if (!visible) return null;

    return (
        <>
        <div className="modal-overlay"/>
        
        <div className="account-sidebar-container">
            <button className="account-sidebar-button-close" onClick={handleCloseClick}>&times;</button>

            <img className="account-rank-image" src={`/rank_${auth.userData.rank.currentRank}.png`} alt="rank"></img>
            <h1>{auth.userData.telephoneNumber}</h1>

            <div className="account-balance-container">
                <img src="/coin.png"></img>
                <p>{auth.userData.wallet.balance.toFixed(2)}</p>
                <ReloadOutlined className="account-balance-reload"/>
            </div>

            <div className="account-sidebar-button-container">
                <button className="account-sidebar-button" onClick={handleAccountClick}><img src="/member.png"/>ข้อมูลบัญชี</button>
                <button className="account-sidebar-button" onClick={handleDepositClick}><img src="/deposit_icon.png"/>ฝากเงิน</button>
                <button className="account-sidebar-button" onClick={handleWithdrawClick}><img src="/withdraw_icon.png"/>ถอนเงิน</button>
                <button className="account-sidebar-button" onClick={handleTransactionHistoryClick}><img src="/cards.png"/>ประวัติธุรกรรม</button>
                <button className="account-sidebar-button" onClick={handlePromotionHistoryClick}><img src="/cards.png"/>ประวัติโปรโมชั่น</button>
                <button className="account-sidebar-button" onClick={handleGetBonusClick}><img src="/commission_temp.png"/>ขอรับโบนัส</button>
                <button className="account-sidebar-button" onClick={handleInviteFriendClick}><img src="/invite_friend_temp.png"/>ชวนเพื่อน</button>
                <button className="account-sidebar-button" onClick={handleLogoutClick}><img src="/logout_icon.png"/>ออกจากระบบ</button>
            </div>
        </div>
        </>
    );
};

export default AccountSidebar;