import React, { useState, useEffect } from 'react';
import HeaderWithMenu from '../components/HeaderWithMenu';
import FooterWithProvider from '../components/FooterWithProvider';
import FooterWithCopyright from '../components/FooterWithCopyright';
import FooterWithButton from '../components/FooterWithButton';
import AnnouncementBar from '../components/AnnouncementBar';
import SelectType from '../components/SelectType';
import PageMark from '../components/ฺPageMark';
import MainGameAndProvider from '../components/MainGameAndProvider';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import LoginModal from '../components/LoginModal';
import RegisterModal from '../components/RegisterModal';
import MenuModal from '../components/MenuModal';
import AccountSidebar from '../components/AccountSidebar';

import AccountModal from '../components/AccountSection/AccountModal';
import ManageBankModal from '../components/AccountSection/ManageBankModal';
import DepositModal from '../components/AccountSection/DepositModal';
import WithdrawModal from '../components/AccountSection/WithdrawModal';
import TransactionHistoryModal from '../components/AccountSection/TransactionHistoryModal';
import PromotionHistoryModal from '../components/AccountSection/PromotionHistoryModal';
import RetrieveBonusModal from '../components/AccountSection/RetrieveBonusModal';
import CommissionModal from '../components/CommissionModal';


function MainPage() {
  
  const [selectedCategory, setSelectedCategory] = useState('SLOT');
  const [selectedType, setSelectedType] = useState('');

  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [isRegisterModalVisible, setIsRegisterModalVisible] = useState(false);
  const [isMenuModalVisible, setIsMenuModalVisible] = useState(false);
  const [isAccountSidebarVisible, setIsAccountSidebarVisible] = useState(false);
  
  const [isAccountModalVisible, setIsAccountModalVisible] = useState(false);
  const [isManageBankModalVisible, setIsManageBankModalVisible] = useState(false);
  const [isDepositModalVisible, setIsDepositModalVisible] = useState(false);
  const [isWithdrawModalVisible, setIsWithdrawModalVisible] = useState(false);
  const [isTransactionHistoryModalVisible, setIsTransactionHistoryModalVisible] = useState(false);
  const [isPromotionHistoryModalVisible, setIsPromotionHistoryModalVisible] = useState(false);
  const [isRetrieveBonusModalVisible, setIsRetrieveBonusModalVisible] = useState(false);
  
  const [isCommissionModalVisible, setIsCommissionModalVisible] = useState(false);
  
  
  const showLoginModal = () => setIsLoginModalVisible(true);
  const cancelLoginModal = () => setIsLoginModalVisible(false);

  const showRegisterModal = () => setIsRegisterModalVisible(true);
  const cancelRegisterModal = () => setIsRegisterModalVisible(false);

  const showMenuModal = () => setIsMenuModalVisible(true);
  const cancelMenuModal = () => setIsMenuModalVisible(false);

  const showAccountSidebar = () => setIsAccountSidebarVisible(true);
  const cancelAccountSidebar = () => setIsAccountSidebarVisible(false);

  const showAccountModal = () => setIsAccountModalVisible(true);
  const cancelAccountModal = () => setIsAccountModalVisible(false);

  const showManageBankModal = () => setIsManageBankModalVisible(true);
  const cancelManageBankModal = () => setIsManageBankModalVisible(false);
  
  const showDepositModal = () => setIsDepositModalVisible(true);
  const cancelDepositModal = () => setIsDepositModalVisible(false);

  const showWithdrawModal = () => setIsWithdrawModalVisible(true);
  const cancelWithdrawModal = () => setIsWithdrawModalVisible(false);

  const showTransactionHistoryModal = () => setIsTransactionHistoryModalVisible(true);
  const cancelTransactionHistoryModal = () => setIsTransactionHistoryModalVisible(false);

  const showPromotionHistoryModal = () => setIsPromotionHistoryModalVisible(true);
  const cancelPromotionHistoryModal = () => setIsPromotionHistoryModalVisible(false);

  const showRetrieveBonusModal = () => setIsRetrieveBonusModalVisible(true);
  const cancelRetrieveBonusModal = () => setIsRetrieveBonusModalVisible(false);

  const showCommissionModal = () =>  setIsCommissionModalVisible(true);
  const cancelCommissionModal = () => setIsCommissionModalVisible(false);

  const handleGameClick = () => console.log('Game Click');

  useEffect(() => {
    console.log(selectedCategory);
  }, [selectedCategory]);

  const handleSlotClick = () => {
    setSelectedCategory("SLOT");
  }

  const handleCasinoClick = () => {
    setSelectedCategory("CASINO");
  }

  const handleSportClick = () => {
    setSelectedType("SPORT");
  }

  const handleCardClick = () => {
    setSelectedCategory("CARD");
  }

  const handleFishingClick = () => {
    setSelectedCategory("FISH");
  }

  const handlePokerClick = () => {
    setSelectedCategory("POKER");
  }

  const handleKenoClick = () => {
    setSelectedCategory("KENO");
  }

  const handleTradingClick = () => {
    setSelectedCategory("TRADE");
  }

  const handleEsportClick = () => {
    setSelectedCategory("ESPORT");
  }

  const handleCockClick = () => {
    setSelectedCategory("COCK");
  }

  return (
    <div className="background-element">
      <HeaderWithMenu onLoginClick={showLoginModal} onMenuClick={showMenuModal} onProfileClick={showAccountSidebar}/>
      <AnnouncementBar />
      <div className="main-content-container">
        <Carousel 
          autoPlay
          infiniteLoop
          showThumbs={false}
          showStatus={false}
          interval={3000}
          stopOnHover
          dynamicHeight
        >
          <div>
              <img src="/slide1.jpg" alt="Slide 1" style={{ background: 'none' }} />
          </div>
          <div>
              <img src="/slide2.jpg" alt="Slide 2" style={{ background: 'none' }}/>
          </div>
          <div>
              <img src="/slide3.jpg" alt="Slide 3" style={{ background: 'none' }}/>
          </div>
        </Carousel>
      </div>
      
      <SelectType onSlotClick={handleSlotClick} onCasinoClick={handleCasinoClick} onSportClick={handleSportClick} onCardsClick={handleCardClick}
                  onFishingClick={handleFishingClick} onPokerClick={handlePokerClick} onKenoClick={handleKenoClick} onTradingClick={handleTradingClick}
                  onEsportClick={handleEsportClick} onCockClick={handleCockClick}/>
                  
      
      <MainGameAndProvider category={selectedCategory} gameType={selectedType} onLoginRedirect={showLoginModal} />
      
      <FooterWithProvider />
      <FooterWithCopyright />
      <FooterWithButton onLogin={showLoginModal} onHomeClick={handleGameClick}/>

      <LoginModal visible={isLoginModalVisible} onClose={cancelLoginModal} onRegisterClick={showRegisterModal} />
      <RegisterModal visible={isRegisterModalVisible} onClose={cancelRegisterModal} onLogin={showLoginModal}/>
      <MenuModal visible={isMenuModalVisible} onClose={cancelMenuModal} onLogin={showLoginModal} onRegisterClick={showRegisterModal} onCommissionClick={showCommissionModal} />
      <AccountSidebar visible={isAccountSidebarVisible} onClose={cancelAccountSidebar} onAccountClick={showAccountModal} onDepositClick={showDepositModal} onWithdrawClick={showWithdrawModal} onTransactionHistoryClick={showTransactionHistoryModal} onPromotionHistoryClick={showPromotionHistoryModal} onGetBonusClick={showRetrieveBonusModal} />
      
      <AccountModal visible={isAccountModalVisible} onClose={cancelAccountModal} onManageBankClick={showManageBankModal}/>
      <ManageBankModal visible={isManageBankModalVisible} onClose={cancelManageBankModal}/>
      <DepositModal visible={isDepositModalVisible} onClose={cancelDepositModal}/>
      <WithdrawModal visible={isWithdrawModalVisible} onClose={cancelWithdrawModal}/>
      <TransactionHistoryModal visible={isTransactionHistoryModalVisible} onClose={cancelTransactionHistoryModal}/>
      <PromotionHistoryModal visible={isPromotionHistoryModalVisible} onClose={cancelPromotionHistoryModal}/>
      <RetrieveBonusModal visible={isRetrieveBonusModalVisible} onClose={cancelRetrieveBonusModal}/>


      <CommissionModal visible={isCommissionModalVisible} onClose={cancelCommissionModal} />
      
    </div>
  );
}

export default MainPage;
