import React, { useState } from 'react';
import HeaderWithMenu from '../components/HeaderWithMenu';
import FooterWithProvider from '../components/FooterWithProvider';
import FooterWithCopyright from '../components/FooterWithCopyright';
import FooterWithButton from '../components/FooterWithButton';
import AnnouncementBar from '../components/AnnouncementBar';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import LoginModal from '../components/LoginModal';
import RegisterModal from '../components/RegisterModal';
import MenuModal from '../components/MenuModal';
import AccountSidebar from '../components/AccountSidebar';

import AccountModal from '../components/AccountSection/AccountModal';

import DepositModal from '../components/AccountSection/DepositModal';
import WithdrawModal from '../components/AccountSection/WithdrawModal';
import TransactionHistoryModal from '../components/AccountSection/TransactionHistoryModal';
import PromotionHistoryModal from '../components/AccountSection/PromotionHistoryModal';
import RetrieveBonusModal from '../components/AccountSection/RetrieveBonusModal';
import CommissionModal from '../components/CommissionModal';

import './RankingPage.css';

function RankingPage() {
  
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [isRegisterModalVisible, setIsRegisterModalVisible] = useState(false);
  const [isMenuModalVisible, setIsMenuModalVisible] = useState(false);
  const [isAccountSidebarVisible, setIsAccountSidebarVisible] = useState(false);
  
  const [isAccountModalVisible, setIsAccountModalVisible] = useState(false);
  const [isDepositModalVisible, setIsDepositModalVisible] = useState(false);
  const [isWithdrawModalVisible, setIsWithdrawModalVisible] = useState(false);
  const [isTransactionHistoryModalVisible, setIsTransactionHistoryModalVisible] = useState(false);
  const [isPromotionHistoryModalVisible, setIsPromotionHistoryModalVisible] = useState(false);
  const [isRetrieveBonusModalVisible, setIsRetrieveBonusModalVisible] = useState(false);
  
  const [isCommissionModalVisible, setIsCommissionModalVisible] = useState(false);

  const showLoginModal = () => setIsLoginModalVisible(true);
  const cancelLoginModal = () => setIsLoginModalVisible(false);

  const showRegisterModal = () => setIsRegisterModalVisible(true);
  const cancelRegisterModal = () => setIsRegisterModalVisible(false);

  const showMenuModal = () => setIsMenuModalVisible(true);
  const cancelMenuModal = () => setIsMenuModalVisible(false);

  const showAccountSidebar = () => setIsAccountSidebarVisible(true);
  const cancelAccountSidebar = () => setIsAccountSidebarVisible(false);

  const showAccountModal = () => setIsAccountModalVisible(true);
  const cancelAccountModal = () => setIsAccountModalVisible(false);

  const showDepositModal = () => setIsDepositModalVisible(true);
  const cancelDepositModal = () => setIsDepositModalVisible(false);

  const showWithdrawModal = () => setIsWithdrawModalVisible(true);
  const cancelWithdrawModal = () => setIsWithdrawModalVisible(false);

  const showTransactionHistoryModal = () => setIsTransactionHistoryModalVisible(true);
  const cancelTransactionHistoryModal = () => setIsTransactionHistoryModalVisible(false);

  const showPromotionHistoryModal = () => setIsPromotionHistoryModalVisible(true);
  const cancelPromotionHistoryModal = () => setIsPromotionHistoryModalVisible(false);

  const showRetrieveBonusModal = () => setIsRetrieveBonusModalVisible(true);
  const cancelRetrieveBonusModal = () => setIsRetrieveBonusModalVisible(false);

  const showCommissionModal = () =>  setIsCommissionModalVisible(true);
  const cancelCommissionModal = () => setIsCommissionModalVisible(false);

  const handleGameClick = () => console.log('Game Click');

  return (
    <div className="ranking-background-element">
      <HeaderWithMenu onLoginClick={showLoginModal} onMenuClick={showMenuModal} onProfileClick={showAccountSidebar}/>
      <AnnouncementBar />
      <div className="ranking-content-container">
        <div className="ranking-profile-container">
          <h1>ระดับของท่าน</h1>
          <div className="rank-flex">
            <img src="/rank_bronze.png"></img>
            <p>Bronze</p>
          </div>
        </div>

        <h1>เพื่อไต่ขึ้นระดับสูงสุด</h1>
        <div className="ranking-next-rank-container">
          
          <div className="ranking-next-rank-card">
            <img src="/rank_bronze.png"></img>
            <h3>ข้อกำหนด</h3>
            <p>สมาชิกใหม่</p>
          </div>

          <div className="ranking-next-rank-card">
            <img src="/rank_silver.png"></img>
            <h3>ข้อกำหนด</h3>
            <p>ยอดฝาก 10,000 บาท ต่อเดือน</p>
          </div>

          <div className="ranking-next-rank-card">
            <img src="/rank_gold.png"></img>
            <h3>ข้อกำหนด</h3>
            <p>ยอดฝาก 10,000 บาท ต่อเดือน</p>
          </div>
        </div>

        <h1>โบนัสคืนยอดเดิมพัน</h1>

        <div className="ranking-commission-container">
          
          <div className="ranking-next-rank-card">
            <img src="/rank_bronze.png"></img>
            <h3>คืนยอดเดิมพัน</h3>
            <p>0.60 %</p>
          </div>

          <div className="ranking-next-rank-card">
            <img src="/rank_silver.png"></img>
            <h3>คืนยอดเดิมพัน</h3>
            <p>0.80 %</p>
          </div>

          <div className="ranking-next-rank-card">
            <img src="/rank_gold.png"></img>
            <h3>คืนยอดเดิมพัน</h3>
            <p>0.90 %</p>
          </div>
        </div>

        <h1>โบนัสคืนยอดเสีย</h1>
        
        <div className="ranking-rebate-container">
          
          <div className="ranking-next-rank-card">
            <img src="/rank_bronze.png"></img>
            <h3>คืนยอดเสีย</h3>
            <p>4.88 %</p>
          </div>

          <div className="ranking-next-rank-card">
            <img src="/rank_silver.png"></img>
            <h3>คืนยอดเสีย</h3>
            <p>6.88 %</p>
          </div>

          <div className="ranking-next-rank-card">
            <img src="/rank_gold.png"></img>
            <h3>คืนยอดเสีย</h3>
            <p>8.88 %</p>
          </div>
        </div>
      </div>
      
      <FooterWithProvider />
      <FooterWithCopyright />
      <FooterWithButton onRegisterClick={showRegisterModal} onLogin={showLoginModal} onHomeClick={handleGameClick}/>

      <LoginModal visible={isLoginModalVisible} onClose={cancelLoginModal} onRegisterClick={showRegisterModal} />
      <RegisterModal visible={isRegisterModalVisible} onClose={cancelRegisterModal} onLogin={showLoginModal}/>
      <MenuModal visible={isMenuModalVisible} onClose={cancelMenuModal} onLogin={showLoginModal} onRegisterClick={showRegisterModal} onCommissionClick={showCommissionModal}/>
      <AccountSidebar visible={isAccountSidebarVisible} onClose={cancelAccountSidebar} onAccountClick={showAccountModal} onDepositClick={showDepositModal} onWithdrawClick={showWithdrawModal} onTransactionHistoryClick={showTransactionHistoryModal} onPromotionHistoryClick={showPromotionHistoryModal} onGetBonusClick={showRetrieveBonusModal}/>
      
      <AccountModal visible={isAccountModalVisible} onClose={cancelAccountModal}/>
      <DepositModal visible={isDepositModalVisible} onClose={cancelDepositModal}/>
      <WithdrawModal visible={isWithdrawModalVisible} onClose={cancelWithdrawModal}/>
      <TransactionHistoryModal visible={isTransactionHistoryModalVisible} onClose={cancelTransactionHistoryModal}/>
      <PromotionHistoryModal visible={isPromotionHistoryModalVisible} onClose={cancelPromotionHistoryModal}/>
      <RetrieveBonusModal visible={isRetrieveBonusModalVisible} onClose={cancelRetrieveBonusModal}/>

      <CommissionModal visible={isCommissionModalVisible} onClose={cancelCommissionModal} />
    </div>
  );
}

export default RankingPage;
