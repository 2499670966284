import React, { useState, useContext } from 'react';
import { useAuth } from '../../AuthContext';
import './AccountModal.css';
import { useTranslation } from 'react-i18next';

const AccountModal = ({ visible, onClose, onManageBankClick }) => {
  const { auth} = useAuth();
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const { t } = useTranslation();

  const handleManageBankClick = () => {
    onClose();
    onManageBankClick();
  }

  if (!visible) return null;

  return (
    <>
        <div className="account-modal-container">
            <button className="account-button-close" onClick={onClose}>&times;</button>

            <div className="account-modal-content">
                <h1>{t('account_info')}</h1>
                <div className="account-modal-member-container">
                    <img src={`/rank_${auth.userData.rank.currentRank}.png`} alt="rank"/>
                    <div className="account-modal-member-section">
                        <p>Member : {t(`${auth.userData.rank.currentRank}`)}</p>
                        <h1>{auth.userData.telephoneNumber}</h1>
                        <a>{t('change_password')}</a>
                    </div>
                </div>

                <div className="account-modal-rank-condition-section">
                    <p className="account-modal-rank-condition-top">เงื่อนไขขั้น {t(`${auth.userData.rank.currentRank}`)}</p>
                    <p className="account-modal-rank-condition-bottom">ฝากอีก 1 ครั้ง ยอดฝาก 1,000 บาท</p>
                    <p className="account-modal-rank-condition-description">ขั้น Member จะได้รับสิทธิพิเศษและโปรโมชั่นที่ดียิ่งขึ้น</p>
                </div>
                
                <div className="account-modal-bank-section">
                    <p className="account-modal-bank-header">ข้อมูลบัญชี</p>
                    <p className="account-modal-bank-name"><img src="/ktb_icon.png"/>ธนาคารกรุงไทย</p>
                    <p className="account-modal-bank-number">เลขบัญชี : 465 041 2838</p>
                    <p className="account-modal-bank-fullname">ชื่อบัญชี : จักรวาล ประกอบทอง</p>
                    <button className="account-modal-button" onClick={handleManageBankClick}>Manage Bank Account</button>
                </div>
            </div>
        </div>
    </>

  );
};

export default AccountModal;
