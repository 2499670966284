import React, { useState, useContext } from 'react';
import { useAuth } from '../AuthContext';
import './MenuModal.css';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../ThemeContext';
import { useNavigate } from 'react-router-dom';

const MenuModal = ({ visible, onClose, onLogin, onRegisterClick, onCommissionClick }) => {
  const { auth} = useAuth();

  const { theme, toggleTheme } = useContext(ThemeContext); // Access the theme context

  const { i18n } = useTranslation();

  const navigate = useNavigate();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const { t } = useTranslation();

  const [activeMode, setActiveMode] = useState(theme); // Use the current theme as the default mode

  const handleModeChange = (mode) => {
    setActiveMode(mode);
    toggleTheme(mode); // Toggle the theme globally
  };

  const handleRegisterClick = () => {
    onClose();

    if(!auth.isAuthenticated){
      onRegisterClick();
    }
  };

  const handleRankingClick = () => {
    onClose();
    if(auth.isAuthenticated){
      navigate('/ranking');
    }
    else{
      onLogin();
    }
  };

  const handleCommissionClick = () => {
    onClose();
    
    if(auth.isAuthenticated){
      onCommissionClick();
    }
    else{
      onLogin();
    }
  };

  const handleWheelClick = () => {
    onClose();

    if(auth.isAuthenticated){
      navigate('/wheel');
    }
    else{
      onLogin();
    }
    
  };

  const handleInviteFriendClick = () => {
    onClose();

    if(auth.isAuthenticated){
      navigate('/invite');
    }
    else{
      onLogin();
    }
    
  };

  if (!visible) return null;

  return (
    <>
        <div className="modal-overlay"/>

        <div className="menu-modal-container">
            <button className="menu-button-close" onClick={onClose}>&times;</button>

            <div className="menu-modal-content">
                <h1>{t('menu')}</h1>
                <div className="menu-modal-button-container">

                  {
                    !auth.isAuthenticated && 
                    <div className="menu-modal-button" onClick={handleRegisterClick}>
                      <img src={activeMode === 'light'? "member_light.png" : "/member.png"}></img>
                      <p>{t('register')}</p>
                    </div>
                  }
                  
                  <div className="menu-modal-button" onClick={handleRankingClick}>
                    <img src={activeMode === 'light'? "ranking_light.png" : "/ranking.png"}></img>
                    <p>{t('ranking')}</p>
                  </div>

                  <div className="menu-modal-button" onClick={handleCommissionClick}>
                    <img src={activeMode === 'light'? "commission_temp.png" : "/commission_temp.png"}></img>
                    <p>{t('return_loss')}</p>
                  </div>
                  
                  <div className="menu-modal-button" onClick={handleWheelClick}>
                    <img src={activeMode === 'light'? "wheel_icon_light.png" : "/wheel_icon.png"}></img>
                    <p>{t('wheel')}</p>
                  </div>

                  <div className="menu-modal-button" onClick={handleInviteFriendClick}>
                    <img src="/letjoin.png"></img>
                    <p>{t('invite_friend')}</p>
                  </div>

                </div>

                <div className="menu-modal-theme-container">
                  <h2>{t('mode')}</h2>
                  
                  <div style={{display: "flex", marginTop: "10px"}}>
                    <div className={activeMode === 'light' ? 'dark-mode-button-active' : 'dark-mode-button'}>
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => handleModeChange('light')}
                      >
                        <g fill="none" fillRule="evenodd">
                          <path
                            d="M10.506 2v1.297m7.783 6.487h-1.297M16.01 4.28l-.918.917m.918 10.09-.918-.917m-4.586 2.333V18M2.289 9.784h1.298M4.714 4.28l.917.917m-.917 10.09.917-.917"
                            stroke="#000"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeDasharray="0,0"
                          ></path>
                          <path d="M10.29 14.09a4.09 4.09 0 1 1 0-8.18 4.09 4.09 0 0 1 0 8.18z" fill="#000"></path>
                        </g>
                      </svg>
                    </div>
                    
                    
                    <div className={activeMode === 'dark' ? 'dark-mode-button-active' : 'dark-mode-button'}>
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => handleModeChange('dark')}
                      >
                        <g fill="none" fillRule="evenodd">
                          <path
                            d="M3.322 9.485a7.714 7.714 0 0 0 6.998 8.4c2.04.192 4.082-.387 5.637-1.738 1.069-.87 1.847-2.028 2.332-3.283-2.43.87-5.15.483-7.289-1.062a8.128 8.128 0 0 1-3.11-5.02c-.097-1.351.097-2.606.486-3.861-2.818.965-4.762 3.475-5.054 6.564z"
                            fill="#000"
                            fillRule="evenodd"
                          ></path>
                        </g>
                      </svg>
                    </div>
                  </div>
                  
                </div>

                <div className="menu-modal-language-container">
                  <h2>{t('language')}</h2>
                  <div className="menu-modal-language-list">
                    <img src="/thai_icon.png" onClick={() => changeLanguage('th')}></img>
                    <img src="/english_icon.png" onClick={() => changeLanguage('en')}></img>
                    <img src="/myanmar_icon.png" onClick={() => changeLanguage('my')}></img>
                    <img src="/laos_icon.png" onClick={() => changeLanguage('la')}></img>
                  </div>
                </div>
                
            </div>
        </div>
    </>

  );
};

export default MenuModal;
