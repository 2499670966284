import React from 'react';
import './PageMark.css'; // Import the CSS file

const PageMark = () => {

  return (
    <div className="page-mark-container">
        <img src="/break_line.png" className="page-mark-img"></img>
    </div>
  );
};

export default PageMark;