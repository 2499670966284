import React, { useState } from 'react';
import { Wheel } from 'react-custom-roulette';
import './WheelGameModal.css';

const WheelGameModal = ({ visible, onClose }) => {
  const segments = [
    'Prize 1',
    'Prize 2',
    'Prize 3',
    'Prize 4',
    'Prize 5',
    'Prize 6',
    'Prize 7',
    'Prize 8',
  ];
  const segColors = ['#EE4040', '#F0CF50', '#815CD1', '#3DA5E0', '#FF6347', '#20B2AA', '#FF4500', '#1E90FF'];

  const data = segments.map((segment, index) => ({
    option: segment,
    style: { backgroundColor: segColors[index % segColors.length], textColor: 'white' }
  }));

  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);

  const handleSpinClick = () => {
    if (!mustSpin) {
      const newPrizeNumber = Math.floor(Math.random() * segments.length);
      console.log('New Prize Number:', newPrizeNumber);
      setPrizeNumber(newPrizeNumber);
      setMustSpin(true);
    }
  };

  const onFinished = () => {
    setMustSpin(false);
    console.log(`You won: ${segments[prizeNumber]}`);
  };

  if (!visible) return null;

  return (
    <>
      <div className="modal-overlay" onClick={onClose} />

      <div className="wheelgame-modal-container">
        <button className="wheelgame-button-close" onClick={onClose}>
          &times;
        </button>

        <div className="wheelgame-modal-content">
          <h1>Spin the Wheel</h1>
          <Wheel
            mustStartSpinning={mustSpin}
            prizeNumber={prizeNumber}
            data={data}
            backgroundColors={['#3e3e3e', '#df3428']}
            textColors={['#ffffff']}
            outerBorderColor={'#eeeeee'}
            outerBorderWidth={10}
            innerBorderColor={'#30261a'}
            radiusLineColor={'#eeeeee'}
            radiusLineWidth={8}
            fontSize={18}
            onStopSpinning={onFinished}
            spinDuration={0.5}
          />
          <button className="wheelgame-spin-button" onClick={handleSpinClick}>
            SPIN
          </button>
        </div>
      </div>
    </>
  );
};

export default WheelGameModal;