import React, { useState, useContext } from 'react';
import { Modal, Input } from 'antd';
import { useAuth } from '../AuthContext';
import Cookies from 'js-cookie';
import './RegisterModal.css';

const CommisionModal = ({ visible, onClose }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [registerState, setRegisterState] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleCloseClick = () => {
    onClose();
  }

  if (!visible) return null;

  return (
    <>
      <div className="modal-overlay"/>
    
      <div className="register-modal-container">
        <button className="register-button-close" onClick={handleCloseClick}>&times;</button>
        <h1>คืนยอดเสีย</h1>
      </div>
    </>
  );
};

export default CommisionModal;